<template>

    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :title="type === 0 ? '新增属性' : type === 1 ? '修改属性' : '属性详情'"
        :visible.sync="visible"
        width="40%"
        top="5vh"
        @close="closeDialog"
        @open="openDialog"
        center>

        <el-form :model="form" size="small" :rules="rules" ref="ruleForm" class="effect_form_item"
                 :label-width="formLabelWidth" v-if="type !== 2">

            <el-form-item label="属性名称：" prop="attributeName">
                <el-input
                    v-model.trim="form.attributeName"
                    placeholder="请输入属性名称">
                </el-input>
            </el-form-item>

            <el-form-item label="属性标识：" prop="attributeRemarks">
                <el-input
                    :disabled="serviceType === 1"
                    v-model.trim="form.attributeRemarks"
                    placeholder="请输入属性标识">
                </el-input>
            </el-form-item>

<!--            <el-form-item label="权限：" prop="authority">-->
<!--                <el-radio-group v-model="form.authority">-->
<!--                    <el-radio :label="item1.id" v-for="(item1,index1) in $message.authorityList"-->
<!--                              :disabled="form.format === 7 && (item1.id === 1 || item1.id === 3)" :key="index1">-->
<!--                        {{ item1.name }}-->
<!--                    </el-radio>-->
<!--                </el-radio-group>-->
<!--            </el-form-item>-->

            <el-form-item label="格式：" prop="format">
                <el-radio-group v-model="form.format" @change="clearForm()"
                                :disabled="serviceType === 1">
                    <el-radio :label="item1.id" :disabled="item1.id === 7" v-for="(item1,index1) in $message.formatList" :key="index1">
                        {{ item1.name }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item
                label="类型长度："
                v-show="form.format !== 5 && form.format !== 6"
                :prop="(form.format !== 5 && form.format !== 6) ? 'typeBytes' : ''">
                <el-select v-model="form.typeBytes" :popper-append-to-body="false" placeholder="请选择类型长度" :disabled="form.format !== 0 || serviceType === 1">
                    <el-option
                        v-for="item in $message.typeByteList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>

            <!-- 整数型、小数型 -->
            <el-form-item
                label="数值范围："
                required
                :error="form.err.valueErr"
                v-show="form.format === 0">
                <el-col :span="11">
                    <el-input-number
                        @blur="validateForm"
                        v-model="form.startNum"
                        controls-position="right"
                        :min="form.typeBytes === 1 ? -128 : form.typeBytes === 2 ? -32768 : form.typeBytes === 4 ? -2147483648 : null"
                        :max="form.typeBytes === 1 ? 127 : form.typeBytes === 2 ? 32767 : form.typeBytes === 4 ? 2147863647 : null"
                        :precision="0"></el-input-number>
                </el-col>
                <el-col class="line" :span="2" style="text-align: center;">-</el-col>
                <el-col :span="11">
                    <el-input-number
                        @blur="validateForm"
                        v-model="form.endNum"
                        controls-position="right"
                        :min="form.typeBytes === 1 ? -128 : form.typeBytes === 2 ? -32768 : form.typeBytes === 4 ? -2147483648 : null"
                        :max="form.typeBytes === 1 ? 127 : form.typeBytes === 2 ? 32767 : form.typeBytes === 4 ? 2147863647 : null"
                        :precision="0"></el-input-number>
                </el-col>
            </el-form-item>

            <el-form-item :prop="(form.format === 0) ? 'rate' : ''"
                          v-show="form.format === 0">
                <span slot="label">
                    倍数
                    <el-popover
                        placement="top-start"
                        title=""
                        width="200"
                        trigger="hover"
                        content="数据将除以10的指数，适用于小数，如上报值为1，小数因子设置为1，则转化后数据为0.1">
                        <i
                            :class="objectIcon"
                            @mouseover="objectIcon = 'attribute_coicon icon-yuanyin'"
                            @mouseleave="objectIcon = 'attribute_alicon iconfont_al icon-yiwen'"
                            slot="reference"></i>
                    </el-popover>
                    :
                </span>
                <el-select v-model="form.rate" :popper-append-to-body="false" placeholder="请选择倍数">
                    <el-option
                        v-for="item in $message.rateList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item
                label="步长："
                :error="form.err.stepErr"
                required
                v-show="form.format === 0">
                <el-input-number
                    v-model="form.step"
                    @blur="validateForm"
                    placeholder="请输入属性步长"
                    :max="form.typeBytes === 1 ? 127 : form.typeBytes === 2 ? 32767 : form.typeBytes === 4 ? 2147863647 : null"
                    :min="0"
                    :precision="0"></el-input-number>
            </el-form-item>

            <el-form-item label="单位：" :prop="form.format === 0 ? 'unit' : ''"
                          v-show="form.format === 0">
                <el-input v-model.trim="form.unit" placeholder="请输入属性单位"></el-input>
            </el-form-item>

            <!-- 枚举型/故障型 -->
            <el-form-item :label="form.format === 3 ? '枚举值：' : '故障值：'" required :error="form.err.enumErr"
                          v-if="form.format === 3 || form.format === 7">
                <span v-for="(item1,index1) in form.enumList" :key="index1" class="emun_item">
                    <el-col :span="2" style="text-align: center" v-if="type === 1">
                        {{ item1.id ? item1.id : '-' }}
                    </el-col>
                    <el-col :span="type === 1 ? 8 : 9">
                        <el-input maxlength="30" show-word-limit v-model.trim="item1.key"
                                  :placeholder="form.format === 3 ? '枚举值key' : '故障值key'"></el-input>
                    </el-col>
                    <el-col :span="2" style="text-align: center">
                        —
                    </el-col>
                    <el-col :span="type === 1 ? 8 : 9">
                        <el-input maxlength="30" show-word-limit v-model.trim="item1.name"
                                  :placeholder="form.format === 3 ? '枚举值内容' : '故障值内容'"></el-input>
                    </el-col>
                    <el-col :span="4" class="add_enum">
                        <el-button type="text" icon="el-icon-remove-outline" @click="delEmun(index1)"
                                   v-show="form.enumList.length > 1" style="color: red;margin-left: 0px;"></el-button>
                        <el-button type="text" style="color: #659400;"
                                   v-show="form.enumList.length < 30 && (index1 + 1) === form.enumList.length"
                                   icon="el-icon-circle-plus-outline" @click="addEmun()"></el-button>
                    </el-col>
                </span>
            </el-form-item>

            <!-- 字符型/透传型 -->
            <el-form-item label="最大值：" :error="form.err.stringErr"
                          :prop="form.format === 5 || form.format === 6 ? 'string': ''"
                          v-show="form.format === 5 || form.format === 6">
                <el-input-number v-model="form.string" :placeholder="'最长字节为' + (form.format === 5 ? 255 : 1000)" :max="(form.format === 5 ? 255 : 1000)" :min="0"></el-input-number>
            </el-form-item>

            <el-form-item label="备注：">
                <el-input type="textarea" maxlength="200" show-word-limit :rows="4" v-model="form.remarks"></el-input>
            </el-form-item>

        </el-form>


        <el-form :model="form" size="small" ref="ruleForm" class="effect_form_item"
                 :label-width="formLabelWidth" v-else>
            <el-form-item label="属性名称：">{{ form.attributeName }}</el-form-item>
            <el-form-item label="属性标识：">{{ form.attributeRemarks }}</el-form-item>
            <el-form-item label="格式：">{{ $config.getMsgItemUtil($message.formatList, form.format, 'id', 'name') }}</el-form-item>
            <el-form-item
                label="类型长度："
                v-show="form.format !== 5 && form.format !== 6">
                {{ $config.getMsgItemUtil($message.typeByteList, form.typeBytes, 'id', 'name') }}
            </el-form-item>

            <!-- 整数型、小数型 -->
            <el-form-item label="数值范围：" v-show="form.format === 0">
                {{ form.startNum }} ~ {{ form.endNum }}
            </el-form-item>
            <el-form-item label="倍数：" v-show="form.format === 0">
                {{ $config.getMsgItemUtil($message.rateList, form.rate, 'id', 'name') }}
            </el-form-item>

            <el-form-item label="步长：" v-show="form.format === 0">
                {{ form.step }}
            </el-form-item>
            <el-form-item label="单位：" v-show="form.format === 0">
                {{ form.unit }}
            </el-form-item>

            <!-- 枚举型/故障型 -->
            <el-form-item :label="form.format === 3 ? '枚举值：' : '故障值：'" v-show="form.format === 3 || form.format === 7">
                <el-table
                    size="mini"
                    :data="form.enumList"
                    :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)',fontSize:'14px', paddingLeft: '24px'}"
                    :cell-style="{color:'rgba(0, 0, 0 ,.65)', padding: '16px 24px'}"
                    style="width: 100%;">
                    <el-table-column
                        label="id"
                        prop="id">
                    </el-table-column>
                    <el-table-column
                        label="key"
                        prop="key">
                    </el-table-column>
                    <el-table-column
                        label="名称"
                        prop="name">
                    </el-table-column>
                </el-table>
            </el-form-item>

            <!-- 字符型/透传型 -->
            <el-form-item label="最大值：" v-show="form.format === 5 || form.format === 6">
                {{ form.string }}
            </el-form-item>

            <el-form-item label="备注：">
                {{ form.remarks }}
            </el-form-item>

        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" :loading="loading" @click="submit" v-if="type !== 2">确 认</el-button>
        </span>

    </el-dialog>

</template>

<script>

import {mapState, mapActions} from "vuex";

export default {

    computed: {
        ...mapState('effect', ['attributeDialogVisible', 'attributeDialogType', 'attributeMsgItem']),
        getRequestName() {
            let list = ['addProductAttribute', 'modifyProductAttribute'];
            return list[this.type];
        }
    },

    watch: {

        attributeDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        },

        attributeDialogType: {
            handler(newVal) {
                this.type = newVal;
            }
        },

        attributeMsgItem: {
            handler(newVal) {
                if (JSON.stringify(newVal) === '{}') return;

                this.form.serviceCode = newVal.code;
                this.form.attributeCode = newVal.attributeCode;
                this.serviceType = newVal.effectType;
                if (this.attributeDialogType === 0) return;

                this.getAttributeMsg(newVal);
            },
            deep: true
        }
    },

    data() {

        return {

            formLabelWidth: "100px",

            visible: false,

            loading: false,

            type: 0,

            serviceType: 2,

            objectIcon: "attribute_alicon iconfont_al icon-yiwen",

            form: {
                attributeCode: "",
                attributeName: "",
                attributeRemarks: "",
                rate: 0,
                typeBytes: 1,
                // authority: 1,
                format: 0,
                remarks: "",
                enumList: [{
                    id: "",
                    key: "",
                    name: ""
                }],
                startNum: undefined,
                endNum: undefined,
                step: undefined,
                unit: "",
                string: undefined,
                err: {
                    valueErr: "",
                    enumErr: "",
                    stringErr: "",
                    stepErr: ""
                }
            },

            rules: {
                attributeName: [
                    {required: true, message: "请输入属性名称", trigger: "blur"},
                    {pattern: /^(?=[\S\s]{1,20}$)[\S\s]*/, message: '输入不能超过20个字符'},
                ],
                attributeRemarks: [
                    {required: true, message: "请输入属性标识", trigger: "blur"},
                    {pattern: /^(?=[\S\s]{1,32}$)[\S\s]*/, message: '输入不能超过32个字符'},
                    {pattern: /^[a-z_A-Z_0-9]*$/, message: '只能输入字母、下划线及数字'},
                ],
                rate: [{required: true, message: "请选择倍数", trigger: "blur"}],
                typeBytes: [{required: true, message: "请选择类型长度", trigger: "blur"}],
                // authority: [{required: true, message: "请选择权限", trigger: "blur"}],
                format: [{required: true, message: "请选择格式", trigger: "blur"}],
                step: [{required: true, message: "请输入步长", trigger: "blur"}],
                unit: [{required: true, message: "请输入单位", trigger: "blur"}]
            }

        }

    },

    methods: {

        ...mapActions('effect', ['closeAttributeDialog', 'getProductAttributeDetail']),

        openDialog() {
            this.type = this.attributeDialogType;
        },

        /**
         * 删除枚举
         * @param {枚举下标} index
         */
        delEmun(index) {
            this.form.enumList.splice(index, 1);
        },

        /**
         * 新增枚举
         */
        addEmun() {
            this.form.enumList.push({
                id: "",
                key: "",
                name: ""
            })
        },

        /**
         * 获取属性详情
         * @param {属性数据} msg
         */
        getAttributeMsg(msg) {
            this.getProductAttributeDetail({
                attributeCode: msg.attributeCode,
                productCode: this.$route.query.id
            }).then(res => {
                let data = res.result;

                this.form.format = data.dataType;
                // this.form.authority = data.authority;
                this.form.attributeName = data.name;
                this.form.attributeRemarks = data.attributeKey;
                this.form.startNum = parseInt(data.minValue);
                this.form.endNum =  parseInt(data.maxValue);
                this.form.step = parseInt(data.stepValue);
                this.form.rate = data.rate;
                this.form.typeBytes = data.typeBytes;
                this.form.unit = data.unit;
                this.form.string = data.maxLength;
                this.form.remarks = data.remark;

                this.form.enumList = data.enumValues ? [] : [{name: ""}];
                if (this.form.enumList.length === 0) {
                    let enumList = JSON.parse(data.enumValues);
                    for (let i = 0; i < enumList.length; i++) {
                        let keys = Object.keys(enumList[i]);
                        let key = keys[1] === 'id' ? keys[0] : keys[1];
                        this.form.enumList.push({
                            id: enumList[i].id,
                            key: key,
                            name: enumList[i][key]
                        })
                    }
                }

            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        /**
         * 切换格式重置表单
         */
        clearForm() {
            this.form.enumList = [{id: "", key: "", name: ""}];
            this.form.startNum = undefined;
            this.form.endNum = undefined;
            this.form.step = undefined;
            this.form.unit = "";
            this.form.string = undefined;


            this.form.rate = this.form.format === 0 ? 0 : null;

            if (this.form.format === 3 || this.form.format === 7) {
                this.form.typeBytes = 1;
            } else if (this.form.format === 4 || this.form.format === 0){
                this.form.typeBytes = 1;
            } else {
                this.form.typeBytes = null;
            }
        },

        /**
         * 关闭弹窗回调
         */
        closeDialog() {
            this.closeAttributeDialog();
            this.$refs['ruleForm'].resetFields();
            this.form = {
                attributeCode: "",
                attributeName: "",
                attributeRemarks: "",
                rate: 0,
                typeBytes: 1,
                // authority: 1,
                format: 0,
                remarks: "",
                enumList: [{
                    id: "",
                    key: "",
                    name: ""
                }],
                startNum: undefined,
                endNum: undefined,
                step: undefined,
                unit: "",
                string: undefined,
                err: {
                    valueErr: "",
                    enumErr: "",
                    stringErr: "",
                    stepErr: ""
                }
            };
        },

        /**
         * 验证表单
         */
        validateForm() {
            let enumValues = [];
            let isStop = false;
            this.form.err = {
                valueErr: "",
                enumErr: "",
                stringErr: "",
                stepErr: ""
            }

            if (this.form.format === 3 || this.form.format === 7) {
                this.form.enumList.forEach(item1 => {
                    if (!item1.name || !item1.key) {
                        this.form.err.enumErr = "请填写完列表";
                        isStop = true;
                    }

                    if (!(/^[a-z_0-9]*$/.test(item1.key))) {
                        this.form.err.enumErr = "只能输入字母、下划线及数字";
                        isStop = true;
                    }
                    enumValues.push({
                        [item1.key]: item1.name
                    });
                })
            }

            if ((this.form.format === 0) && (this.form.step === 0)) {
                this.form.err.stepErr = "步长不能为0";
                isStop = true;
            }


            if ((this.form.format === 0) && (this.form.step === undefined)) {
                this.form.err.stepErr = "请输入步长";
                isStop = true;
            }

            if ((this.form.format === 0) && (this.form.startNum >= this.form.endNum)) {
                this.form.err.valueErr = "开始值不能大于等于结束值";
                isStop = true;
            }

            if ((this.form.format === 0) && (this.form.startNum === undefined || this.form.endNum === undefined)) {
                this.form.err.valueErr = "请填写数值范围";
                isStop = true;
            }

            if ((this.form.format === 5 || this.form.format === 6) && this.form.string === undefined) {
                this.form.err.stringErr = "请输入最大字符串";
                isStop = true;
            }

            let data = {
                attributeCode: this.form.attributeCode,
                attributeKey: this.form.attributeRemarks,
                name: this.form.attributeName,
                dataType: this.form.format,
                minValue: this.form.format === 0 ? this.form.startNum : undefined,
                maxValue: this.form.format === 0 ? this.form.endNum : undefined,
                stepValue: this.form.format === 0 ? this.form.step : undefined,
                maxLength: this.form.string,
                unit: this.form.unit,
                remark: this.form.remarks,
                typeBytes: this.form.typeBytes,
                rate: this.form.rate,
                // authority: this.form.authority,
                enumValues: JSON.stringify(enumValues) === '[]' ? null : JSON.stringify(enumValues),
                productCode: this.$route.query.id,
                serviceCode: this.form.serviceCode
            };

            this.$refs.ruleForm.validate((valid) => {
                if (!valid) {
                    isStop = true;
                }
            })

            if (isStop) return "";
            console.log(data)
            return data;
        },

        /**
         * 提交表单
         */
        submit() {
            let data = this.validateForm();
            if (!data) return;
            console.log(data)
            this.loading = true;
            this.$store.dispatch("effect/" + this.getRequestName, data).then(res => {
                this.loading = false;
                this.$dialog.showMessage("操作成功", this.$config.TOAST_SUCCESS);
                this.closeDialog();
                this.$emit("getMsg");
            }, err => {
                this.loading = false;
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        }

    }

}

</script>

<style scoped>

.effect_form_item {
    margin-top: 20px;
}

/deep/ .el-dialog {
    text-align: left !important;
}

/deep/ .el-dialog--center .el-dialog__footer {
    text-align: right !important;
}

/deep/ .el-dialog__body {
    padding-top: 0;
}

/deep/ .el-dialog__title {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
}

/deep/ .el-dialog__header {
    border-bottom: 1px solid #E9E9E9;
    padding: 13px;
}

.el-radio {
    margin-top: 10px;
}

.el-card {
    margin-top: 10px;
    padding-top: 10px;
}

.el-card:first-child {
    margin-top: 0px;
}

.el-card:last-child {
    margin-bottom: 90px;
}

/deep/ .el-card__header {
    padding: 0 10px 0 0;
    border: none;
    text-align: right;
}

/deep/ .el-input-number--small {
    width: 100%;
    min-width: 80px;
}

/* /deep/ .el-drawer__body{
    padding: 25px;
} */
.add_enum {
    text-align: center;
}

.emun_item {
    display: block;
    height: 40px;
}

/deep/ .el-table__header-wrapper {
    border-radius: 8px 8px 0 0;
}

.attribute_alicon {
    color: #3296FA;
    font-size: 16px;
    position: relative;
    top: 1px;
    cursor: pointer;
}

.attribute_coicon {
    color: #3296FA;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    top: 1px;
    font-family: "iconfont" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
</style>
