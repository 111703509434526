<template>

    <div class="body">

        <el-timeline style="margin-top: 20px;">

            <el-timeline-item
                v-for="(item, index) in activities"
                :timestamp="item"
                :key="index"
                color="#1890FF"
                size="large"
                :icon="'iconfont_al step_icon icon-' + (index+1)"
                placement="top">

                <div v-if="index == 0" class="item">
                    <el-row>
                        <el-col :span="5">
                            <el-card
                                :body-style="{padding: '10px',marginBottom: '0'}"
                                shadow="never">
                                JAVA Funlink SDK

                                <el-link type="primary" style="float: right;" :href="url.appSdkUrl">下载</el-link>
                            </el-card>
                        </el-col>
                        <el-col :span="5" style="margin-left: 30px;">
                            <el-card
                                :body-style="{padding: '10px',marginBottom: '0'}"
                                shadow="never">
                                C Funlink SDK
                                <el-link type="primary" style="float: right;" :href="url.deviceSdkUrl">下载</el-link>
                            </el-card>
                        </el-col>
                    </el-row>
                </div>

                <div v-if="index == 1" class="item">
                    <el-button
                        type="primary"
                        plain
                        class="debugging"
                        @click="toAuth">
                        前往授权
                        <i class="el-icon-arrow-right el-icon--right"></i>
                    </el-button>
                </div>

                <div v-if="index == 2" class="item">
                    <el-button
                        type="primary"
                        plain
                        class="debugging"
                        @click="toDebug">
                        前往调试
                        <i class="el-icon-arrow-right el-icon--right"></i>
                    </el-button>
                </div>

                <div v-if="index == 3" class="item">
                    <div class="step4">
                        <div class="header_btn">
                            <div class="title1">固件版本列表</div>
                            <el-button class="btn" size="small" type="primary" @click="add" plain>新建版本</el-button>
                        </div>

                        <el-table
                            ref="firmwareTable"
                            class="firmware_table"
                            :data="firmwareMsg.list"
                            :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)',fontSize:'14px', paddingLeft: '24px'}"
                            :cell-style="{color:'rgba(0, 0, 0 ,.65)', padding: '16px 24px'}"
                            v-loading="loading"
                            style="width: 100%;">
                            <el-table-column
                                label="固件版本"
                                prop="name">
                            </el-table-column>
                            <el-table-column
                                prop="remark"
                                label="更新日志">
                                <template slot-scope="scope">
                                    <span
                                        :title="scope.row.remark"
                                        style="
                                        display: -webkit-box;;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                        -webkit-line-clamp: 2;
                                        -webkit-box-orient: vertical;
                                        white-space: pre-line;
                                        ">
                                        {{ scope.row.remark }}
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="createTime"
                                label="上传时间">
                            </el-table-column>
                            <el-table-column
                                label="状态">
                                <template slot-scope="scope">
                                    <span class="status unpublished" v-if="scope.row.status === 1">未发布</span>
                                    <span class="status published" v-else>已发布</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="操作">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.status === 1">
                                        <el-button type="text" size="small"
                                                   @click="release(scope.row)">发布
                                        </el-button>
                                        <el-button type="text" size="small" style="color:#d45c34;"
                                                   @click="del(scope.row)">删除
                                        </el-button>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>

                        <el-pagination
                            background
                            :current-page="page"
                            @current-change="handleCurrentChange"
                            :page-size="10"
                            layout=" prev, pager, next"
                            style="margin-top: 10px;text-align: right;"
                            :total="parseInt(firmwareMsg.totalRecords)">
                        </el-pagination>
                    </div>
                </div>

            </el-timeline-item>

        </el-timeline>

        <!-- 上传固件包弹窗 -->
        <add-firmware-dialog @getMsg="getMsg"></add-firmware-dialog>

    </div>
</template>

<script>

import AddFirmwareDialog from "./components/dialog/addFirmwareDialog";
import {mapActions, mapState} from 'vuex'

export default {

    name: 'nonEmbedded',

    components: {
        AddFirmwareDialog
    },

    computed: {
        ...mapState('product', ['productMsg'])
    },

    watch: {

        productMsg: {
            handler(newVal) {
                this.productData = newVal;
            }
        }

    },

    data() {

        return {

            accessMode: 0,

            isLookUp: false,

            loading: false,

            firmwareMsg: {},

            productData: {},

            activities: [
                "请选择合适的接入协议",
                "烧录鉴权凭证",
                "设备调试（调试前请确认是否已开发完成）",
                "上传含SDK固件"
            ],

            page: 1,

            voucher: {
                pid: "",
                productSecret: ""
            },

            url: {
                appSdkUrl: "",
                deviceSdkUrl: ""
            }

        }

    },

    mounted() {
        this.productData = this.productMsg;
        // this.getProductBurningInfo({
        //     productCode: this.$route.query.id
        // }).then(res => {
        //     this.voucher = res.result;
        // }, err => {
        //     console.log(err);
        //     this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
        // });
        this.getMsg();
        this.getFirmwareResource({}).then(res => {
            this.url = res.result;
        }, err => {
            console.log(err);
        })
    },

    methods: {

        ...mapActions('firmware', ['queryFirmware', 'getProductBurningInfo', 'delFirmware', 'pubFirmware', 'getFirmwareResource']),
        ...mapActions('product', ['getProductDetail']),

        add() {
            this.$store.commit('firmware/SET_FIRMWARE_DIALOG_TYPE', 1);
            this.$store.commit('firmware/SET_ADD_FIRMWARE_DIALOG_VISIBLE', true);
        },

        toDebug() {
            this.$router.push({path: "/debug", query: {id: this.$route.query.id}});
        },

        toAuth() {
            this.$router.push({path: "/empower"});
        },

        getMsg() {
            this.loading = true;
            this.queryFirmware({
                productCode: this.$route.query.id,
                currPage: this.page,
                pageSize: 10,
                type: 1
            }).then(res => {
                this.firmwareMsg = res.result;
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                setTimeout(() => this.loading = false, 500)
            })
        },

        handleCurrentChange(page) {
            this.page = page;
            this.getMsg()
        },

        release(row) {
            this.$dialog.showConfirm({
                content: "是否发布当前固件？",
                title: "提示",
                btn1: "确认",
                btn2: "取消",
                icon: 2
            }, () => {
                this.pubFirmware({
                    code: row.code
                }).then(res => {
                    this.$dialog.showMessage("发布成功", this.$config.TOAST_SUCCESS);
                    this.getMsg();
                    this.getProductDetail({
                        productCode: this.$route.query.id,
                        isRefresh: true
                    });
                }, err => {
                    console.log(err);
                    this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                })
            }, () => {
            })
        },

        del(row) {
            this.$dialog.showConfirm({
                content: "是否删除当前固件？",
                title: "提示",
                btn1: "确认",
                btn2: "取消",
                icon: 2
            }, () => {
                this.delFirmware({
                    code: row.code
                }).then(res => {
                    this.$dialog.showMessage("删除成功", this.$config.TOAST_SUCCESS);
                    this.getMsg();
                }, err => {
                    console.log(err);
                    this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                })
            }, () => {
            })
        }

    }

}
</script>

<style scoped>
.body {
    width: calc(100% - 60px) !important;
    height: calc(100% - 35px) !important;
    box-shadow: 0 0 4px 0 #BCBCBC;
    border-radius: 8px;
    padding: 16px 20px;
    margin: 10px;
}

.el-card {
    border-radius: 8px;
    border: 1px solid #E1E1E1;
}

.title {
    font-weight: bold;
}

/deep/ .el-table__header-wrapper {
    border-radius: 8px 8px 0 0;
}

.access_mode {
    margin-top: 20px;
}

.item {
    margin: 20px 0 40px 0;
}

/deep/ .el-divider--horizontal {
    margin: 15px 0;
}

/deep/ .el-timeline-item__node--large {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
}

/deep/ .el-timeline-item__tail {
    left: 7px;
}

/deep/ .el-timeline-item__timestamp {
    font-size: 16px;
    color: #000;
}

/deep/ .step_icon {
    margin-top: 3.5px
}

.step2 {
    width: 400px;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    border-radius: 8px;
    padding: 10px;
}

.look_up {
    float: right;
    margin-top: -40px;
}

.step4 {
    width: calc(100% - 100px);
    padding: 10px;
    margin-top: -10px;
}

.header_btn {
    width: 100%;
    height: 30px;
}

.debugging {
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    border-radius: 8px;
}

.btn {
    float: right;
    margin-bottom: 10px;
}

.title1 {
    float: left;
    margin-top: 8px;
    font-size: 16px;
    color: black;
    font-weight: bold;
}

/deep/ .cell {
    padding: 0 !important;
}

.status {
    width: auto;
    font-size: 12px;
    padding: 2px;
    border-radius: 4px;
}

.unpublished {
    border: 1px solid #888888;
    color: #888888;
}

.published {
    border: 1px solid #389E0D;
    color: #389E0D;
}
</style>

