<template>

    <el-drawer
        title="添加功能"
        :visible.sync="drawer"
        :wrapperClosable="false"
        direction="rtl"
        size="40%"
        :before-close="handleClose">

        <el-divider></el-divider>

        <el-form :model="form" size="small" :rules="rules" ref="ruleForm" class="effect_form"
                 :label-width="formLabelWidth">

            <el-form-item label="功能名称：" prop="effectName">
                <el-input
                    v-model.trim="form.effectName"
                    placeholder="请输入功能名称">
                </el-input>
            </el-form-item>

            <el-form-item label="功能标识：" prop="effectRemarks">
                <el-input
                    v-model.trim="form.effectRemarks"
                    placeholder="请输入功能标识">
                </el-input>
            </el-form-item>

            <el-form-item label="权限：" prop="authority">
                <el-radio-group v-model="form.authority">
                    <el-radio :label="item1.id" v-for="(item1,index1) in $message.authorityList" :key="index1">
                        {{ item1.name }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item label="备注：">
                <el-input
                    type="textarea"
                    v-model="form.remark"
                    :rows="4"
                    show-word-limit
                    maxlength="200"
                    placeholder="请输入备注">
                </el-input>
            </el-form-item>

        </el-form>

        <el-divider></el-divider>

        <el-row>
            <el-col :span="12" class="title">
                功能属性
            </el-col>
            <el-col :span="12" class="btn_add">
                <el-button type="text" icon="el-icon-plus" v-show="isShowAddFormBtn"
                           @click="$refs['customEffctItem'].addForm()">添加属性
                </el-button>
            </el-col>
        </el-row>

        <custom-effct-item ref="customEffctItem" class="card_item" @change="setAddFormType"></custom-effct-item>

        <div class="demo-drawer__footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">{{ loading ? '提交中 ...' : '确 定' }}</el-button>
        </div>

    </el-drawer>

</template>

<script>

import {mapState, mapActions} from "vuex";
import customEffctItem from '@/views/product/views/effect/components/customEffctItem'

export default {

    computed: {
        ...mapState('effect', ['addEffectDialogVisible'])
    },

    components: {
        customEffctItem
    },

    watch: {

        addEffectDialogVisible: {
            handler(newVal) {
                this.drawer = newVal;
            }
        }

    },

    data() {

        return {

            loading: false,

            formLabelWidth: "100px",

            drawer: false,

            isShowAddFormBtn: true,

            form: {
                effectName: "",
                effectRemarks: "",
                remark: "",
                authority: 1
            },

            rules: {
                effectName: [
                    {required: true, message: "请输入功能名称", trigger: "blur"},
                    {pattern: /^(?=[\S\s]{1,20}$)[\S\s]*/, message: '输入不能超过25个字符'},
                ],
                authority: [{required: true, message: "请选择权限", trigger: "blur"}],
                effectRemarks: [
                    {required: true, message: "请输入功能标识", trigger: "blur"},
                    {pattern: /^(?=[\S\s]{1,32}$)[\S\s]*/, message: '输入不能超过32个字符'},
                    {pattern: /^[a-z_A-Z_0-9]*$/, message: '只能输入字母、下划线及数字'},
                ],
            }

        }

    },

    methods: {

        ...mapActions('effect', ['addProductService', 'closeAddEffectDialog']),

        handleClose() {
            this.closeAddEffectDialog()
            this.$refs.ruleForm.resetFields();
            this.$refs['customEffctItem'].clearForm();
            this.form = {
                effectName: "",
                effectRemarks: "",
                remark: "",
                authority: 1
            }
        },

        setAddFormType(val) {
            console.log(val)
            this.isShowAddFormBtn = val;
        },

        async submit() {
            let customList = await this.$refs.customEffctItem.getFormList();
            this.$refs.ruleForm.validate((valid) => {
                if (!customList) return;
                if (valid) {
                    this.loading = true;
                    this.addProductService({
                        attrs: customList,
                        authority: this.form.authority,
                        name: this.form.effectName,
                        productCode: this.$route.query.id,
                        serviceKey: this.form.effectRemarks,
                        type: 2,
                        remark: this.form.remark,
                    }).then(res => {
                        this.loading = false;
                        this.handleClose();
                        this.$dialog.showMessage("添加成功", this.$config.TOAST_SUCCESS);
                        this.$emit("getMsg");
                    }, err => {
                        this.loading = false;
                        this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                    })
                } else {
                    //当功能表单错误时直接滚动定位到最顶部，而第一个el-drawer分割线就是最顶部位置
                    let div = document.getElementsByClassName('el-drawer__body')[0];
                    let form = document.getElementsByClassName('el-divider')[0];
                    //45.2为整个弹窗标题高度，减去这个高度能使表单不被标题遮挡
                    div.scrollTo(0,form.offsetTop - 45.2);
                }
            })
        }

    }

}
</script>

<style scoped>
/deep/ .el-drawer__header {
    font-weight: bold !important;
    text-align: left;
    color: black !important;
    padding: 10px;
    margin-bottom: 0px;
}

/deep/ .el-drawer__body {
    padding: 0 10px;
}

/deep/ .el-divider--horizontal {
    margin: 0px;
}

.effect_form {
    margin-top: 18px;
}

.title {
    text-align: left;
    font-weight: bold;
    margin-top: 18px;
}

.btn_add {
    text-align: right;
    margin-top: 12px;
}

/deep/ .demo-drawer__footer {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 999;
    padding: 10px;
    width: 40%;
    text-align: right;
    background-color: white;
}

.effect_attribute {
    height: 100%;
}

.card_item {
    margin-top: 10px;
}
</style>
