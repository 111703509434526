<template>

    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :title="type === 1 ? '上传SDK固件包' : type === 2 ? '上传MCU固件' : '新增固件'"
        :visible.sync="visible"
        width="30%"
        @close="closeDialog"
        @open="openDialog">

        <el-form :model="form" size="small" :rules="rules" ref="ruleForm" class="firmware_form"
                 :label-width="formLabelWidth">

            <el-form-item label="固件版本：" prop="firmwareVersion">
                <el-input
                    v-model.trim="form.firmwareVersion"
                    placeholder="格式仅支持字母及数字及小数点">
                </el-input>
            </el-form-item>

            <el-form-item label="固件包：" required :error="err">
                <el-upload
                    class="upload-demo"
                    ref="upload"
                    :action="$message.uploadImgUrl"
                    :data="{
                        bucket: 'firmware'
                    }"
                    :headers="{
                        accessToken: $token.getToken().accessToken,
                        refreshToken: $token.getToken().refreshToken
                    }"
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :before-remove="beforeRemove"
                    :on-success="handleSuccess"
                    :limit="1"
                    accept=".img,.bin,.zip,.hex,.apk"
                    :file-list="fileList"
                    :on-exceed="handleExceed">
                    <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
            </el-form-item>

            <el-form-item label="更新日志：" prop="log">
                <el-input
                    type="textarea"
                    v-model="form.log"
                    :rows="4"
                    show-word-limit
                    maxlength="300"
                    placeholder="请输入更新日志">
                </el-input>
            </el-form-item>

        </el-form>


        <div class="demo-drawer__footer" style="text-align: right;margin-top: 50px">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" :loading="loading" @click="submit()">确 定</el-button>
        </div>

    </el-dialog>

</template>

<script>
import {mapState, mapActions} from "vuex";

export default {
    name: "addFirmwareDialog",

    computed: {
        ...mapState('firmware', ['addFirmwareDialogVisible', 'firmwareDialogType'])
    },

    watch: {

        addFirmwareDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        },

        firmwareDialogType: {
            handler(newVal) {
                this.type = newVal;
            }
        }

    },

    data() {

        return {

            visible: false,

            type: 1,

            loading: false,

            form: {
                firmwareVersion: "",
                log: ""
            },

            err: "",

            rules: {
                firmwareVersion: [
                    {required: true, message: "请输入固件版本号", trigger: "blur"},
                    {pattern: /^(?=[\S\s]{1,20}$)[\S\s]*/, message: '输入不能超过20个字符'},
                    {pattern: /^[a-zA-Z0-9-.]*$/, message: '只能输入字母及数字及小数点'},
                ],
                log: [
                    {required: true, message: "请输入更新日志", trigger: "blur"}
                ]
            },

            file: "",

            fileList: [],

            formLabelWidth: "100px"

        }

    },

    methods: {

        ...mapActions('firmware', ['closeAddFirmwareDialog', 'addFirmware']),

        openDialog() {},

        closeDialog() {
            this.closeAddFirmwareDialog();
            this.$refs.ruleForm.resetFields();
            this.form = {
                firmwareVersion: "",
                log: ""
            };
            this.err = "";
            this.file = "";
            this.fileList = [];
        },

        handleSuccess(res, file) {
            if (res.success) {
                this.file = res.result.fileList[0].url;
            } else {
                this.$dialog.showMessage(res.resultMsg, this.$config.TOAST_ERROR);
                this.$refs.upload.clearFiles();
                console.log(res, file)
            }
        },

        handleRemove(file, fileList) {
            console.log(file, fileList);
        },

        handlePreview(file) {
            console.log(file);
        },

        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },

        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`).then(() => {
                this.file = ""
            });
        },

        submit() {
            console.log(this.file)
            let isStop = false;
            if (!this.file) {
                this.err = "请上传固件";
                isStop = true;
            }
            if (this.type === 3) {
                //测试
                this.$dialog.showMessage('上传成功', this.$config.TOAST_SUCCESS);
                this.$emit('getMsg');
                this.closeDialog();
                return
            }
            this.$refs.ruleForm.validate((valid) => {
                if (!isStop && valid) {
                    this.err = "";
                    this.loading = true;
                    this.addFirmware({
                        name: this.form.firmwareVersion,
                        productCode: this.$route.query.id,
                        remark: this.form.log,
                        url: this.file,
                        type: this.type
                    }).then(res => {
                        this.$dialog.showMessage('上传成功', this.$config.TOAST_SUCCESS);
                        this.$emit('getMsg');
                        this.closeDialog();
                    }, err => {
                        this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                    }).finally(() => {
                        this.loading = false
                    })
                }
            })
        }

    }

}
</script>

<style scoped>

.firmware_form {
    margin-top: 20px;
}

/deep/ .el-dialog {
    text-align: left !important;
}

/deep/ .el-dialog--center .el-dialog__footer {
    text-align: right !important;
}

/deep/ .el-dialog__body {
    padding-top: 0;
}

/deep/ .el-dialog__title {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
}

/deep/ .el-dialog__header {
    border-bottom: 1px solid #E9E9E9;
    padding: 13px;
}
</style>
