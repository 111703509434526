<template>

    <div class="body">

        <div class="title">请选择接入方式</div>

        <div class="access_mode">
            <el-radio v-model="accessMode" :label="0" size="medium">线下自研</el-radio>
            <el-radio v-model="accessMode" :label="1" size="medium" disabled>线上生成</el-radio>
        </div>

        <el-divider></el-divider>

        <el-timeline style="margin-top: 20px;">

            <el-timeline-item
                v-for="(item, index) in activities"
                :timestamp="item"
                :key="index"
                color="#1890FF"
                size="large"
                :icon="'iconfont_al step_icon icon-' + (index+1)"
                placement="top">

                <div v-if="index == 0" class="item">
                    <el-row>
                        <el-col :span="5">
                            <el-card
                                :body-style="{padding: '10px',marginBottom: '0'}"
                                shadow="never">
                                应用SDK
                                <el-link type="primary" style="float: right;" :href="appUrl">下载</el-link>
                            </el-card>
                        </el-col>
                    </el-row>
                </div>

                <div v-if="index == 1" class="item">
                    <el-button
                        type="primary"
                        plain
                        class="debugging"
                        @click="toDebug">
                        前往调试
                        <i class="el-icon-arrow-right el-icon--right"></i>
                    </el-button>
                </div>

                <div v-if="index == 2" class="item">
                    <div class="step4">
                        <div class="header_btn">
                            <div class="title1">版本列表</div>
                            <el-button class="btn" size="small" type="primary" @click="add" plain>新建版本</el-button>
                        </div>

                        <el-table
                            ref="panelTable"
                            class="panel_table"
                            :data="panelMsg.list"
                            :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)',fontSize:'14px', paddingLeft: '24px'}"
                            :cell-style="{color:'rgba(0, 0, 0 ,.65)', padding: '16px 24px'}"
                            v-loading="loading"
                            style="width: 100%;">
                            <el-table-column
                                label="版本号"
                                prop="name">
                            </el-table-column>
                            <el-table-column
                                prop="remark"
                                label="更新日志">
                                <template slot-scope="scope">
                                    <span
                                        :title="scope.row.remark"
                                        style="
                                        display: -webkit-box;;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                        -webkit-line-clamp: 2;
                                        -webkit-box-orient: vertical;
                                        white-space: pre-line;
                                        ">
                                        {{ scope.row.remark }}
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="createTime"
                                label="上传时间">
                            </el-table-column>
                            <el-table-column
                                label="状态">
                                <template slot-scope="scope">
                                    <span class="status unpublished" v-if="scope.row.status === 1">未发布</span>
                                    <span class="status published" v-else>已发布</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="操作">
                                <template slot-scope="scope">
                                    <el-button type="text" size="small" v-if="scope.row.status == 1"
                                               @click="release(scope.row)">发布
                                    </el-button>
                                    <el-button type="text" size="small" style="color:#d45c34;"
                                               @click="del(scope.row)" v-if="scope.row.status == 1">删除
                                    </el-button>
                                    <el-popover
                                        placement="right"
                                        trigger="click">
                                        <span :ref="'qrCode' + scope.row.code"
                                              :class="'qrCode' + scope.row.code"></span>
                                        <el-button type="text" size="small" :style="{
                                            marginLeft: scope.row.status == 1 ? '10px' : '0'
                                        }" slot="reference"
                                                   @click="preview(scope.row.code)">预览
                                        </el-button>
                                    </el-popover>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination
                            background
                            :current-page="page"
                            @current-change="handleCurrentChange"
                            :page-size="10"
                            layout=" prev, pager, next"
                            style="margin-top: 10px;text-align: right;"
                            :total="parseInt(panelMsg.totalRecords)">
                        </el-pagination>
                    </div>
                </div>

            </el-timeline-item>

        </el-timeline>

        <!-- 上传RN应用包弹窗 -->
        <add-panel-dialog @getMsg="getMsg"></add-panel-dialog>

    </div>
</template>

<script>

import AddPanelDialog from "./components/dialog/addPanelDialog";
import {mapActions, mapState} from 'vuex';
import RFS from "../../../../utils/RFS";
import QRCode from "qrcodejs2";

export default {

    components: {
        AddPanelDialog
    },

    computed: {
        ...mapState('product', ['productMsg'])
    },

    watch: {

        productMsg: {
            handler(newVal) {
                this.productData = newVal;
            }
        }

    },

    data() {

        return {

            accessMode: 0,

            isLookUp: false,

            loading: false,

            panelMsg: {},

            page: 1,

            productData: {},

            activities: [
                "请下载应用开发资料",
                "应用开发调试",
                "上传RN应用包（上传前请确认调试完成）"
            ],

            appUrl: ""

        }

    },

    mounted() {
        this.productData = this.productMsg;
        this.getMsg();
        this.getPanelResource({}).then(res => {
            this.appUrl = res.result.appUrl;
        }, err => {
            console.log(err);
        })
    },

    methods: {

        ...mapActions('panel', ['queryApply', 'delApply', 'pubApply', 'getPanelResource', 'generatePreviewCode']),
        ...mapActions('product', ['getProductDetail']),

        add() {
            this.$store.commit('panel/SET_ADD_PANEL_DIALOG_VISIBLE', true)
        },

        toDebug() {
            this.$router.push({path: "/debug", query: {id: this.$route.query.id}});
        },

        getMsg() {
            this.loading = true;
            this.queryApply({
                productCode: this.$route.query.id,
                currPage: this.page,
                pageSize: 10
            }).then(res => {
                this.panelMsg = res.result;
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                setTimeout(() => this.loading = false, 500)
            })
        },

        handleCurrentChange(page) {
            this.page = page;
            this.getMsg();
        },

        preview(id){
            this.generatePreviewCode({
                applyCode: id
            }).then(res => {
                this.getQRCode(res.result.code, id);
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        getQRCode(code, id) {
            let dom = this.$refs['qrCode' + id][0];
            let father = document.getElementsByClassName('qrCode' + id)[0];
            let childrens = father.children;
            for (let i = 0; i < childrens.length; i++) {
                father.removeChild(father.lastChild);
            }
            setTimeout(() => {
                // let url = this.$config.url;
                let API_HOST = window.location.protocol+"//"+window.location.host+"/"
                let url = API_HOST + "#/download"
                let msg = this.$config.assembleURLArg(url, 'code', code);
                console.log(msg);
                new QRCode(dom, {
                    text: msg,
                    width: 150,
                    height: 150,
                    colorDark: "black",
                    colorLight: "white"
                });
            })
        },

        release(row) {
            this.$dialog.showConfirm({
                content: "是否发布当前应用？",
                title: "提示",
                btn1: "确认",
                btn2: "取消",
                icon: 2
            }, () => {
                this.pubApply({
                    applyCode: row.code
                }).then(res => {
                    this.$dialog.showMessage("发布成功", this.$config.TOAST_SUCCESS);
                    this.getMsg();
                    this.getProductDetail({
                        productCode: this.$route.query.id,
                        isRefresh: true
                    });
                }, err => {
                    console.log(err);
                    this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                })
            }, () => {
            })
        },

        del(row) {
            this.$dialog.showConfirm({
                content: "是否删除当前应用？",
                title: "提示",
                btn1: "确认",
                btn2: "取消",
                icon: 2
            }, () => {
                this.delApply({
                    applyCode: row.code
                }).then(res => {
                    this.$dialog.showMessage("删除成功", this.$config.TOAST_SUCCESS);
                    this.getMsg();
                }, err => {
                    console.log(err);
                    this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                })
            }, () => {
            })
        }

    }

}
</script>

<style scoped>
.body {
    width: calc(100% - 60px) !important;
    height: calc(100% - 35px) !important;
    box-shadow: 0 0 4px 0 #BCBCBC;
    border-radius: 8px;
    padding: 16px 20px;
    margin: 10px;
}

.el-card {
    border-radius: 8px;
    border: 1px solid #E1E1E1;
}

.debugging {
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    border-radius: 8px;
}

.title {
    font-weight: bold;
}

.access_mode {
    margin-top: 20px;
}

/deep/ .el-table__header-wrapper {
    border-radius: 8px 8px 0 0;
}

/deep/ .step_icon {
    margin-top: 3.5px
}

.item {
    margin: 20px 0 40px 0;
}

/deep/ .el-divider--horizontal {
    margin: 15px 0;
}

/deep/ .el-timeline-item__node--large {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
}

/deep/ .el-timeline-item__tail {
    left: 7px;
}

/deep/ .el-timeline-item__timestamp {
    font-size: 16px;
    color: #000;
}

.step4 {
    width: calc(100% - 100px);
    padding: 10px;
    margin-top: -10px;
}

.header_btn {
    width: 100%;
    height: 30px;
}

.btn {
    float: right;
    margin-bottom: 10px;
}

.title1 {
    float: left;
    margin-top: 8px;
    font-size: 16px;
    color: black;
    font-weight: bold;
}

/deep/ .cell {
    padding: 0 !important;
}

.status {
    width: auto;
    font-size: 12px;
    padding: 2px;
    border-radius: 4px;
}

.unpublished {
    border: 1px solid #888888;
    color: #888888;
}

.published {
    border: 1px solid #389E0D;
    color: #389E0D;
}
</style>
