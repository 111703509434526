<template>

    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="上传RN应用包"
        :visible.sync="visible"
        width="35%"
        @close="closeDialog"
        @open="openDialog">

        <el-form :model="form" size="small" :rules="rules" ref="ruleForm" class="firmware_form"
                 :label-width="formLabelWidth">

            <el-form-item label="版本号：" prop="panelVersion">
                <el-input
                    v-model.trim="form.panelVersion"
                    placeholder="格式仅支持字母及数字及小数点">
                </el-input>
            </el-form-item>

            <el-form-item label="RN应用包：" required :error="err">

                <el-row>
                    <el-col :span="12">
                        <div class="upload-title">Android包：</div>
                        <el-upload
                            ref="upload"
                            class="upload-div"
                            :action="$message.uploadImgUrl"
                            :show-file-list="false"
                            :data="{
                               bucket: 'panel',
                               holdName: 'Y' // 保持上传文件名不变
                            }"
                                    :headers="{
                                accessToken: $token.getToken().accessToken,
                                refreshToken: $token.getToken().refreshToken
                            }"
                            :on-preview="handlePreview"
                            :on-remove="handleRemove"
                            :on-success="($event) => handleSuccess($event, 'android')"
                            :before-upload="($event) => beforeUpload($event, 'android')"
                            v-loading="load.android"
                            accept=".zip">
                            <img src="../../../../../../assets/icon_file.png" class="icon-file" v-if="file.android"/>
                            <i v-else class="el-icon-plus uploader-icon"></i>
                            <!--                    <el-button size="small" type="primary">点击上传</el-button>-->
                        </el-upload>
                    </el-col>
                    <el-col :span="12">
                        <div class="upload-title">IOS包：</div>
                        <el-upload
                            ref="upload"
                            class="upload-div"
                            :action="$message.uploadImgUrl"
                            :show-file-list="false"
                            :data="{
                               bucket: 'panel',
                               holdName: 'Y' // 保持上传文件名不变
                            }"
                                    :headers="{
                                accessToken: $token.getToken().accessToken,
                                refreshToken: $token.getToken().refreshToken
                            }"
                            :on-preview="handlePreview"
                            :on-remove="handleRemove"
                            :on-success="($event) => handleSuccess($event, 'ios')"
                            :before-upload="($event) => beforeUpload($event, 'ios')"
                            v-loading="load.ios"
                            accept=".zip">
                            <img src="../../../../../../assets/icon_file.png" class="icon-file" v-if="file.ios"/>
                            <i v-else class="el-icon-plus uploader-icon"></i>
                            <!--                    <el-button size="small" type="primary">点击上传</el-button>-->
                        </el-upload>
                    </el-col>
                </el-row>
            </el-form-item>

            <el-form-item label="更新日志：" prop="log">
                <el-input
                    type="textarea"
                    v-model.trim="form.log"
                    :rows="4"
                    show-word-limit
                    maxlength="300"
                    placeholder="请输入更新日志">
                </el-input>
            </el-form-item>

        </el-form>

        <div class="demo-drawer__footer" style="text-align: right;margin-top: 50px">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确 定</el-button>
        </div>

    </el-dialog>

</template>

<script>

import {mapState, mapActions} from "vuex";

export default {

    name: "addPanelDialog",

    computed: {
        ...mapState('panel', ['addPanelDialogVisible']),
        ...mapState('product', ['productMsg'])
    },

    watch: {

        addPanelDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        },

        //产品详情
        productMsg: {
            handler(newVal) {
                this.pid = newVal.pid;
            },
            deep: true
        }

    },

    data() {
        let validateVersion = (rule, value, callback) => {
            if (this.version.ios && value !== this.version.ios) {
                callback(new Error('输入版本号与上传RN应用包版本号不一致'));
            } else if (this.version.android && value !== this.version.android) {
                callback(new Error('输入版本号与上传RN应用包版本号不一致'));
            } else {
                callback();
            }
        };

        return {

            visible: false,

            loading: false,

            form: {
                panelVersion: "",
                pid: "",
                log: ""
            },

            pid: "",

            err: "",

            rules: {
                panelVersion: [
                    {required: true, message: "请输入RN应用版本号", trigger: "blur"},
                    {pattern: /^(?=[\S\s]{1,20}$)[\S\s]*/, message: '输入不能超过20个字符'},
                    {pattern: /^[a-zA-Z0-9-.]*$/, message: '只能输入字母及数字及小数点'},
                    { validator: validateVersion, trigger: 'blur' }
                ],
                log: [
                    {required: true, message: "请输入更新日志", trigger: "blur"}
                ]
            },

            file: {
                ios: "",
                android: ""
            },

            load: {
                ios: false,
                android: false
            },

            version: {
                ios: "",
                android: ""
            },

            formLabelWidth: "100px"

        }

    },

    methods: {

        ...mapActions('panel', ['closeAddPanelDialog', 'addApply', 'appLyAddCheck']),

        openDialog() {
            this.pid = this.productMsg.pid;
        },

        closeDialog() {
            this.closeAddPanelDialog();
            this.$refs.ruleForm.resetFields();
            this.form = {
                panelVersion: "",
                log: ""
            };
            this.version = {
                ios: "",
                android: ""
            }
            this.file = {
                ios: "",
                android: ""
            }
            this.err = "";
        },

        beforeUpload(file, platform) {
            let fileNameList = file.name.split('_');
            if (fileNameList.length !== 4) {
                this.err = "RN包名不正确";
                // this.$dialog.showMessage('RN包名不正确', this.$config.TOAST_WARNING, 5000);
                return false;
            }

            if (fileNameList[0] !== `${platform}RnApp`) {
                this.err = `当前上传的RN包不属于${platform}平台`;
                return false;
            }

            if (platform === 'android' && this.version.ios && this.version.ios !== fileNameList[1]) {
                this.err = `当前上传的android包与ios包版本号不一致`;
                return false;
            }

            if (platform === 'ios' && this.version.android && this.version.android !== fileNameList[1]) {
                this.err = `当前上传的ios包与android包版本号不一致`;
                return false;
            }

            if (this.pid !== fileNameList[2]) {
                this.err = `当前上传的${platform}包的PID不属于本产品`;
                return false;
            }

            this.load[platform] = true;
            this.version[platform] = fileNameList[1];
            this.form.panelVersion = fileNameList[1];
            this.form.pid = fileNameList[2];
            return true;
        },

        handleSuccess(res, platform) {
            console.log(res, platform)
            this.load[platform] = false;
            if (res.success) {
                this.file[platform] = res.result.fileList[0].url;
                this.err = ""
            } else {
                this.$dialog.showMessage(res.resultMsg, this.$config.TOAST_ERROR);
                this.$refs.upload.clearFiles();
            }
        },

        handleRemove(file, fileList) {
            console.log(file, fileList);
        },

        handlePreview(file) {
            console.log('handlePreview =>', file);
        },

        submit() {
            let isStop = false;
            if (!this.file.ios) {
                this.err = "请上传ios应用";
                isStop = true;
            }
            if (!this.file.android) {
                this.err = "请上传android应用";
                isStop = true;
            }

            this.$refs.ruleForm.validate((valid) => {
                if (!isStop && valid) {
                    this.appLyAddCheck({
                        name: this.form.panelVersion,
                        productCode: this.$route.query.id,
                        pid: this.form.pid
                    }).then(res => {
                        if (res.success) {
                            this.err = "";
                            this.loading = true;
                            this.addApply({
                                name: this.form.panelVersion,
                                productCode: this.$route.query.id,
                                remark: this.form.log,
                                url: this.file.android,
                                iosUrl: this.file.ios
                            }).then(res => {
                                this.$dialog.showMessage('上传成功', this.$config.TOAST_SUCCESS);
                                this.$emit('getMsg');
                                this.closeDialog();
                            }, err => {
                                console.log(err);
                                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                            }).finally(() => {
                                this.loading = false
                            })
                        } else {
                            this.$dialog.showMessage('校验错误，请检查上传RN包版本号与PID是否正确', this.$config.TOAST_WARNING, 5000);
                        }
                    }, err => {
                        this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                    })
                }
            })
        }

    }

}
</script>

<style scoped>

.firmware_form {
    margin-top: 20px;
}

/deep/ .el-dialog {
    text-align: left !important;
}

/deep/ .el-dialog--center .el-dialog__footer {
    text-align: right !important;
}

/deep/ .el-dialog__body {
    padding-top: 0;
}

/deep/ .el-dialog__title {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
}

/deep/ .el-dialog__header {
    border-bottom: 1px solid #E9E9E9;
    padding: 13px;
}

.upload-title {
    float: left;
    font-weight: 400;
    font-size: 12px;
}

.upload-div {
    float: left;
}

/deep/ .upload-div .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    width: 90px;
    height: 90px;
    position: relative;
    overflow: hidden;
}
/deep/ .upload-div .el-upload:hover {
    border-color: #409EFF;
}
.uploader-icon {
    font-size: 28px;
    color: #8c939d;
    line-height: 90px;
    text-align: center;
}

.icon-file {
    width: 50px;
    height: 50px;
    margin-top: 20px;
}

</style>
