<template>
    <el-drawer
        title="基础配置"
        :visible.sync="drawer"
        :size="600"
        direction="rtl"
        append-to-body
        :wrapperClosable="false"
        @open="handleOpen"
        @close="closeNoticeMsgDialog">
        <el-divider></el-divider>
        <el-form
            :model="form" size="small"
            :rules="rules"
            ref="ruleForm"
            class="basic_form"
            :label-width="formLabelWidth">
            <el-form-item label="产品名称：" prop="productName">
                <el-input
                    v-model.trim="form.productName"
                    placeholder="请输入产品名称">
                </el-input>
            </el-form-item>
            <el-form-item label="产品型号：" prop="model">
                <el-input
                    v-model.trim="form.model"
                    placeholder="只能输入小写字母、下划线及数字">
                </el-input>
            </el-form-item>
            <el-form-item label="通讯方式：" prop="communication">
                <el-select v-model="form.communication" :popper-append-to-body="false" disabled placeholder="请选择通讯方式">
                    <el-option
                        v-for="item in $message.protocolTypeList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="配网方式：" v-if="form.communication && networkList.length > 0">
                <el-checkbox-group v-model="form.networkModeList">
                    <!--当通讯方式为wifi时配网方式默认为wifi配网-->
                    <el-checkbox :label="item.code" v-for="item in networkList" disabled>
                        {{ JSON.parse(item.name).zh }}
                    </el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="产品拟物图：">
                <div class="category_item" v-if="form.productImageUrl">
                    <div class="image">
                        <el-button type="text" icon="el-icon-error" size="medium" @click="form.productImageUrl = ''"
                                   class="close_img"></el-button>
                        <img :src="form.productImageUrl" width="94" height="94">
                    </div>
                </div>
                <el-upload
                    v-else
                    class="avatar-uploader"
                    :action="$message.uploadImgUrl"
                    :data="{
                        bucket: 'product'
                    }"
                    :headers="{
                        accessToken: $token.getToken().accessToken,
                        refreshToken: $token.getToken().refreshToken
                    }"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    accept=".png"
                    :on-error="onErr"
                    :before-upload="beforeAvatarUpload">
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <span class="tips">168*168像素,PNG格式,透明底,100KB以内</span>
            </el-form-item>
            <el-form-item label="备注：">
                <el-input type="textarea" maxlength="200" show-word-limit :rows="6" v-model="form.remarks"></el-input>
            </el-form-item>
        </el-form>

        <div class="demo-drawer__footer">
            <el-button type="primary" :loading="loading" @click="save">保 存</el-button>
        </div>
    </el-drawer>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {

    name: "editProductDrawer",

    computed: {
        ...mapState('product', ['editProductDrawerVisible', 'productMsg']),
    },

    watch: {

        editProductDrawerVisible: {
            handler(newVal) {
                this.drawer = newVal;
            }
        },

        productMsg: {
            handler(newVal) {
                this.form = {
                    pid: newVal.pid,
                    state: newVal.status,
                    productName: newVal.name,
                    communication: newVal.protocolType,
                    remarks: newVal.remark,
                    productImageUrl: newVal.icon,
                    model: newVal.model,
                    networkModeList: []
                }
                let networkModeList = [];
                newVal.networkModeList && newVal.networkModeList.forEach(item => {
                    networkModeList.push(item.networkModeCode)
                })
                this.form.networkModeList = networkModeList;

                //获取配网方式
                this.getNetworkModeListByProtocolType({
                    protocolType: newVal.protocolType
                }).then(res => {
                    this.networkList = res.result ? res.result : [];
                }, err => {
                    this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                })
            },
            deep: true
        }

    },

    data() {

        return {

            drawer: false,

            formLabelWidth: "120px",

            loading: false,

            rules: {
                productName: [
                    {required: true, message: "请输入产品名称", trigger: "blur"},
                    {pattern: /^(?=[\S\s]{1,20}$)[\S\s]*/, message: '输入不能超过20个字符'},
                ],
                communication: [{required: true, message: "请选择通讯方式", trigger: "blur"}],
                model: [
                    {pattern: /^(?=[\S\s]{1,20}$)[\S\s]*/, message: '输入不能超过20个字符'},
                    {pattern: /^[a-z_A-Z_0-9]*$/, message: '只能输入大小写字母、下划线及数字'},
                ]
            },

            form: {
                pid: "",
                state: 1,
                productName: "",
                productType: "",
                communication: 3,
                remarks: "",
                productImageUrl: "",
                model: "",
                networkModeList: []
            },

            networkList: [],

            err: {
                fileErr: "",
                productTypeErr: ""
            },

        }

    },

    methods: {

        ...mapActions('product', ['closeEditProductDrawer', 'getProductDetail', 'modifyProduct', 'getNetworkModeListByProtocolType']),

        getMsg() {
            this.getProductDetail({
                productCode: this.$route.query.id,
                isRefresh: false
            });
        },

        save() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.cleanErr();
                    this.loading = true;
                    console.log(this.$route.query.id)
                    this.modifyProduct({
                        code: this.$route.query.id,
                        icon: this.form.productImageUrl,
                        model: this.form.model,
                        name: this.form.productName,
                        remark: this.form.remarks,
                    }).then(res => {
                        this.loading = false;
                        this.isDisabled = true;
                        this.getMsg();
                        this.closeNoticeMsgDialog();
                        this.$dialog.showMessage("修改成功", this.$config.TOAST_SUCCESS);
                    }, err => {
                        this.loading = false;
                        this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                    });
                }
            })
        },

        cleanErr() {
            this.err = {
                productTypeErr: "",
                fileErr: ""
            }
        },

        handleAvatarSuccess(res, file) {
            if (res.success) {
                this.form.productImageUrl = res.result.fileList[0].url;
            } else {
                this.$dialog.showMessage(res.resultMsg, this.$config.TOAST_ERROR);
                console.log(res, file)
            }
        },

        beforeAvatarUpload(file) {
            const isPNG = file.type === 'image/png';
            const isLt2M = file.size / 1024 < 100;
            if (!isPNG) {
                this.$dialog.showMessage('产品拟物图仅支持PNG格式！', this.$config.TOAST_WARNING);
                return false;
            }
            if (!isLt2M) {
                this.$dialog.showMessage('上传图片大小不能超过 100KB！', this.$config.TOAST_WARNING);
                return false;
            }
            const isSize = new Promise(function (resolve, reject) {
                let width = 168;
                let height = 168;
                let _URL = window.URL || window.webkitURL;
                let image = new Image();
                image.onload = function () {
                    let valid = image.width == width && image.height == height;
                    valid ? resolve() : reject();
                };
                image.src = _URL.createObjectURL(file);
                console.log(image.src)
            }).then(
                () => {
                    return file;
                },
                () => {
                    this.$dialog.showMessage('上传图片尺寸不符合,只能是168*168!', this.$config.TOAST_WARNING);
                    return Promise.reject();
                }
            );
            console.log(file)

            return isPNG && isLt2M && isSize;
        },

        onErr(err, file, fileList) {
            console.log(err)
            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_WARNING);
        },

        handleOpen() {
            this.getMsg();
        },

        closeNoticeMsgDialog() {
            this.closeEditProductDrawer();
        }

    }

}
</script>

<style scoped>
.category_item {
    margin-bottom: 10px;
    text-align: center;
    padding-top: 10px;
}


.image {
    position: relative;
    width: 94px;
    height: 94px;
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 10px;
}

.close_img {
    color: black;
    position: absolute;
    right: 8px;
    margin-top: -12px;
}

.tips {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.25);
}

/deep/ .el-drawer__header {
    font-weight: bold !important;
    text-align: left;
    color: black !important;
    padding: 10px;
    margin-bottom: 0px;
}

/deep/ .el-drawer__body {
    padding: 0 10px;
}

/deep/ .el-divider--horizontal {
    margin: 0;
}

/deep/ .el-breadcrumb__inner, /deep/ .el-breadcrumb__separator {
    font-weight: bold !important;
}

.el-select, .el-input, .el-textarea {
    width: 90%;
}

.basic_form {
    margin-top: 30px;
}

.avatar-uploader .el-upload, .avatar-uploader-icon {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover, .avatar-uploader-icon:hover {
    border-color: #1c204f;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.avatar {
    width: 100px;
    height: 100px;
    display: block;
}

.edit_btn {
    margin-left: 120px;
}

.product_card {
    width: 100px;
    text-align: center;
}

.card_title {
    text-align: center;
    padding: 0 5px 5px 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.clean_btn {
    margin: 5px 5px -10px 0;
    padding: 0 !important;
    float: right;
    position: relative;
    z-index: 999;
}

/deep/ .demo-drawer__footer {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 999;
    padding: 10px;
    width: 600px;
    text-align: right;
    background-color: white;
}
</style>
