<template>

    <div>

        <div class="body">

            <div class="header_btn">
                <div class="title">标准功能</div>
                <el-button class="btn" size="small" type="primary" plain @click="showStandard"
                           :disabled="productData.status == 3 || standardList.length === 0">标准功能</el-button>
                <el-button class="btn" size="small" style="margin-right: 10px;" type="primary" plain
                           @click="leadingOut">
                    导出所有功能
                </el-button>
            </div>

            <el-table
                ref="effectStandardTable"
                class="effect_standard_table"
                border
                :span-method="objectSpanMethod"
                :data="effectStandardList"
                :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)'}"
                :cell-style="{color:'rgba(0, 0, 0 ,.65)'}"
                v-loading="loadingStandard"
                :style="{
                    width: '100%'
                }">

                <el-table-column
                    width="50"
                    prop="serviceId"
                    label="SID">
                </el-table-column>

                <el-table-column
                    prop="effectName"
                    label="功能名称">
                </el-table-column>

                <el-table-column
                    prop="effectRemarks"
                    label="功能标识">
                </el-table-column>

                <el-table-column
                    prop="attributeName"
                    label="属性名称">
                </el-table-column>

                <el-table-column
                    width="50"
                    prop="attributeId"
                    label="AID">
                </el-table-column>

                <el-table-column
                    prop="attributeRemarks"
                    label="属性标识">
                </el-table-column>

                <el-table-column
                    label="数据类型">
                    <template slot-scope="scope">
                        {{ $config.getMsgItemUtil($message.formatList, scope.row.format, 'id', 'name') }}
                    </template>
                </el-table-column>

                <el-table-column
                    width="140"
                    label="属性备注">
                    <template slot-scope="scope">
                        <span style="white-space: pre-line;">{{ scope.row.remarks }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                    label="权限">
                    <template slot-scope="scope">
                        {{ $config.getMsgItemUtil($message.authorityList, scope.row.authority, 'id', 'name') }}
                    </template>
                </el-table-column>

                <el-table-column
                    label="功能备注"
                    prop="remark">
                </el-table-column>

                <el-table-column width="100" label="属性操作" :key="Math.random()">
                    <template slot-scope="scope">
                        <div>
                            <el-button type="text" size="small" @click="editAttribute(scope.row, 1)"  v-if="productData.status !== 3">编辑</el-button>
                            <el-button type="text" size="small" @click="editAttribute(scope.row, 2)">详情</el-button>
<!--                            <el-button type="text" size="small" @click="delAttribute(scope.row)" style="color:#d45c34;">-->
<!--                                删除-->
<!--                            </el-button>-->
                        </div>
                    </template>
                </el-table-column>

                <el-table-column width="200" label="功能操作" :key="Math.random()">
                    <template slot-scope="scope">
                        <div>
<!--                            <el-button type="text" size="small" @click="addAttribute(scope.row)"-->
<!--                                       v-show="scope.row.rowspan < 20" v-if="productData.status !== 3">添加属性-->
<!--                            </el-button>-->
                            <el-button type="text" size="small" @click="editEffect(scope.row, 0)" v-if="productData.status !== 3">编辑</el-button>
                            <el-button type="text" size="small" style="color:#d45c34;" @click="delEffect(scope.row)" v-if="productData.status !== 3">删除</el-button>
                            <el-button type="text" size="small" @click="editEffect(scope.row, 1)">详情</el-button>
                        </div>
                    </template>
                </el-table-column>

            </el-table>

            <div class="header_btn" style="margin-top: 10px;">
                <div class="title">自定义功能</div>
                <el-button class="btn" size="small" type="primary" plain @click="showCustom"
                           :disabled="productData.status == 3">自定义功能
                </el-button>
            </div>

            <el-table
                ref="effectTable"
                class="effect_table"
                border
                :span-method="objectSpanMethod"
                :data="effectList"
                @cell-mouse-enter="cellMouseEnter"
                @cell-mouse-leave="cellMouseLeave"
                :row-class-name="tableRowClassName"
                :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)'}"
                :cell-style="{color:'rgba(0, 0, 0 ,.65)'}"
                v-loading="loading"
                :style="{
                    width: '100%'
                }">

                <el-table-column
                    width="50"
                    prop="serviceId"
                    label="SID">
                </el-table-column>

                <el-table-column
                    prop="effectName"
                    label="功能名称">
                </el-table-column>

                <el-table-column
                    prop="effectRemarks"
                    label="功能标识">
                </el-table-column>

                <el-table-column
                    prop="attributeName"
                    label="属性名称">
                </el-table-column>

                <el-table-column
                    width="50"
                    prop="attributeId"
                    label="AID">
                </el-table-column>

                <el-table-column
                    prop="attributeRemarks"
                    label="属性标识">
                </el-table-column>

                <el-table-column
                    label="数据类型">
                    <template slot-scope="scope">
                        {{ $config.getMsgItemUtil($message.formatList, scope.row.format, 'id', 'name') }}
                    </template>
                </el-table-column>

                <el-table-column
                    width="140"
                    label="属性备注">
                    <template slot-scope="scope">
                        <span style="white-space: pre-line;">{{ scope.row.remarks }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                    label="权限">
                    <template slot-scope="scope">
                        {{ $config.getMsgItemUtil($message.authorityList, scope.row.authority, 'id', 'name') }}
                    </template>
                </el-table-column>

                <el-table-column
                    label="功能备注"
                    prop="remark">
                </el-table-column>

                <el-table-column width="100" label="属性操作" :key="Math.random()">
                    <template slot-scope="scope">
                        <span v-if="productData.status != 3">
                            <el-button type="text" size="small" @click="editAttribute(scope.row, 1)">编辑</el-button>
                            <el-button type="text" size="small" @click="delAttribute(scope.row)" style="color:#d45c34;">
                                删除
                            </el-button>
                        </span>
                        <span style="margin-left: 10px;">
                            <el-button type="text" size="small" @click="editAttribute(scope.row, 2)">详情</el-button>
                        </span>
                    </template>
                </el-table-column>

                <el-table-column width="200" label="功能操作" :key="Math.random()">
                    <template slot-scope="scope">
                        <span v-if="productData.status != 3">
                            <el-button type="text" size="small" @click="addAttribute(scope.row)"
                                       v-show="scope.row.rowspan < 20">添加属性
                            </el-button>
                            <el-button type="text" size="small" @click="editEffect(scope.row, 0)">编辑</el-button>
                            <el-button type="text" size="small" style="color:#d45c34;" @click="delEffect(scope.row)">删除
                            </el-button>
                        </span>
                        <span style="margin-left: 10px;">
                            <el-button type="text" size="small" @click="editEffect(scope.row, 1)">详情</el-button>
                        </span>
                    </template>
                </el-table-column>

            </el-table>

            <!-- 添加功能弹窗 -->
            <add-effect-dialog @getMsg="getMsg"></add-effect-dialog>

            <!-- 属性弹窗 -->
            <attribute-dialog @getMsg="getMsg"></attribute-dialog>

            <!-- 修改功能弹窗 -->
            <edit-effect-dialog @getMsg="getMsg"></edit-effect-dialog>

            <!-- 添加功能 -->
            <add-standard-functions-dialog @getMsg="getMsg" @getStandardList="getStandardList"></add-standard-functions-dialog>

        </div>

        <div class="body" style="margin-top: 20px;">

            <div class="header_btn">
                <div class="title">
                    功能配置
                    <el-popover
                        placement="top-start"
                        title=""
                        width="200"
                        trigger="hover"
                        content="根据您的需求配置相关的服务，若不需要请点击关闭">
                        <i
                            :class="icon"
                            @mouseover="icon = 'question_coicon icon-yuanyin'"
                            @mouseleave="icon = 'question_alicon iconfont_al icon-yiwen'" slot="reference"></i>
                    </el-popover>
                </div>
            </div>

            <el-row :gutter="20" style="margin-top: 30px">
                <el-empty description="暂无功能配置项" :image-size="100" v-if="configList.length === 0"></el-empty>
                <el-col :span="8" v-for="(item, index) in configList" :key="index">
                    <div class="config_item">
                        <div class="config_item_title">
                            {{ item.title }}
                        </div>
                        <el-switch
                            :disabled="item.isDisabled"
                            @change="switchConfig($event, item.code, index)"
                            style="float: right;"
                            v-model="item.isOpen">
                        </el-switch>
                        <div class="config_item_details" :title="item.details">
                            {{ item.details }}
                        </div>
                        <div style="text-align: right;position: absolute;bottom: 10px;right: 10px;">
                            <el-link :href="item.descriptionUrl" target="_blank" type="primary" style="padding: 0;">
                                查看详情
                            </el-link>
                        </div>
                    </div>
                </el-col>

            </el-row>

        </div>

    </div>
</template>

<script>

import {mapState, mapActions} from "vuex";
import addEffectDialog from '@/views/product/views/effect/components/dialog/addEffectDialog';
import attributeDialog from '@/views/product/views/effect/components/dialog/attributeDialog';
import editEffectDialog from '@/views/product/views/effect/components/dialog/editEffectDialog';
import AddStandardFunctionsDialog from "@/views/product/views/effect/components/dialog/addStandardFunctionsDialog";

export default {

    computed: {
        ...mapState('product', ['productMsg'])
    },

    watch: {

        productMsg: {
            handler(newVal) {
                this.productData = newVal;
                this.queryServiceList();
                this.getStandardList();
            }
        }

    },

    components: {
        AddStandardFunctionsDialog,
        addEffectDialog,
        attributeDialog,
        editEffectDialog
    },

    data() {

        return {

            loading: false,

            loadingStandard: false,

            tableHeight: 50,

            effectList: [],

            effectStandardList: [],

            //未选标准列表
            standardList: [],

            productData: {},

            icon: "question_alicon iconfont_al icon-yiwen",

            //配置服务列表
            configList: [],

            //准备上传的配置服务信息
            configureService: [],

            hoverIndex: null,

        }

    },

    mounted() {
        this.productData = this.productMsg;
        setTimeout(() => {
            this.tableHeight = (window.innerHeight - 480) / 2;
        }, 100)
        window.addEventListener('resize', this.setHeight);
        this.getMsg();
    },

    destroyed() {
        window.removeEventListener('resize', this.setHeight);
    },

    beforeUpdate () {
        // table数据更新后，重新渲染table避免闪动
        this.$nextTick(() => {
            this.$refs['effectStandardTable'].doLayout()
            this.$refs['effectTable'].doLayout()
        })
    },

    methods: {

        ...mapActions('effect', ['queryProductServiceList', 'deleteProductService', 'deleteProductAttribute', 'exportFunction', 'getStandardServiceList']),
        ...mapActions('product', ['queryConfigureServiceList', 'modifyProduct']),

        setHeight() {
            this.tableHeight = window.innerHeight - 45 - 240;
        },

        switchConfig(isOpen, code, index) {
            let item = this.configureService;
            item[index].onOff = isOpen ? 1 : 0;
            this.modifyProduct({
                code: this.productData.code,
                // name: this.productData.name,
                configureService: JSON.stringify(item)
            }).then(res => {
                this.$dialog.showMessage("设置成功", this.$config.TOAST_SUCCESS);
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        tableRowClassName({row}){
            if (this.hoverIndex === row.code) {
                return "hover-bg";
            }
        },

        /**
         * ⿏标移⼊表格行
         * @param row
         * @param column
         * @param cell
         * @param event
         */
        cellMouseEnter(row, column, cell, event) {
            this.hoverIndex = row.code;
        },

        /**
         * ⿏标移出表格行
         */
        cellMouseLeave() {
            this.hoverIndex = null;
        },

        /**
         * 查询当前品类的配置服务列表
         */
        queryServiceList() {
            this.queryConfigureServiceList({
                categoryCode: this.productData.categoryCode
            }).then(res => {
                let list = [];

                //产品信息中所带的配置服务信息
                let configServer = this.productData.configureService ? JSON.parse(this.productData.configureService) : [];
                let configServerList = Array.isArray(configServer) ? configServer : [configServer];

                //判断产品详情中是否有配置服务信息
                if (configServerList.length > 0) {

                    //如果产品详情中存在配置服务信息
                    //则将产品详情中的配置服务信息与跟据品类查询出的配置服务列表进行信息同步
                    res.result.forEach(item => {
                        configServerList.forEach(val => {
                            if (val.code === item.code) {
                                list.push({
                                    id: item.id,
                                    code: item.code,
                                    //判断该配置服务是否为必选
                                    title: item.name + (item.associationType === "1" ? "（必选）" : ""),
                                    details: item.remark,
                                    descriptionUrl: item.descriptionUrl,
                                    //当配置服务为必选或者开关为打开时将卡片开关设置为打开
                                    isOpen: val.onOff === 1 || item.associationType === "1",
                                    //判断该配置服务是否为必选，必选时开关设置为禁用
                                    isDisabled: item.associationType === "1"
                                });
                            }
                        })
                    })

                } else {

                    //如果产品中不存在配置服务信息则直接采用品类查询出的配置服务列表
                    res.result.forEach((item, index) => {
                        list.push({
                            id: item.id,
                            code: item.code,
                            //判断该配置服务是否为必选
                            title: item.name + (item.associationType === "1" ? "（必选）" : ""),
                            details: item.remark,
                            descriptionUrl: item.descriptionUrl,
                            //当配置服务为必选时将卡片开关设置为打开
                            isOpen: item.associationType === "1",
                            //判断该配置服务是否为必选，必选时开关设置为禁用
                            isDisabled: item.associationType === "1"
                        });
                        configServerList.push({
                            code: item.code,
                            onOff: item.associationType === "1" ? 1 : 0
                        })
                    })

                }
                this.configList = list;
                this.configureService = configServerList;
                //每次打开时都将配置服务信息同步上传到产品信息中
                this.modifyProduct({
                    code: this.productData.code,
                    // name: this.productData.name,
                    configureService: JSON.stringify(this.configureService)
                }).then(res => {
                }, err => {
                    this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                })
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        /**
         * 处理格式化功能定义数据
         * @param {*} data
         */
        effectDataHandle(data, type) {
            let list = this.$config.deepCopy(data);
            console.log(list)
            let effectList = [];
            list.forEach(item => {
                if (type !== item.type) return;
                item.productAttributeList.forEach((item1, index1) => {
                    console.log(item1.enumValues)
                    effectList.push({
                        code: item.code,
                        attributeCode: item1.code,
                        attributeId: item1.attributeId,
                        effectName: item.name,
                        effectRemarks: item.serviceKey,
                        effectType: item.type,
                        attributeName: item1.name,
                        attributeRemarks: item1.attributeKey,
                        authority: item.authority,
                        format: item1.dataType,
                        rowspan: item.productAttributeList.length > 1 ? (index1 == 0 ? item.productAttributeList.length : 0) : 1,
                        colspan: index1 == 0 ? 1 : 0,
                        remarks: item1.remark,
                        enumList: (!item1.enumValues || item1.enumValues == "[]") ? [] : JSON.parse(item1.enumValues),
                        endNum: item1.maxIntValue,
                        startNum: item1.minIntValue,
                        unit: item1.unit,
                        step: item1.stepIntValue,
                        string: item1.maxLength,
                        remark: item.remark,
                        serviceId: item.serviceId
                    })
                })
            });
            return effectList;
        },

        /**
         * 获取自定义产品功能列表
         */
        getMsg() {
            this.loading = true;
            this.loadingStandard = true;
            this.queryProductServiceList({
                productCode: this.$route.query.id
            }).then(res => {
                if (!res.result) return
                this.effectList = this.effectDataHandle(res.result, 2);
                this.effectStandardList = this.effectDataHandle(res.result, 1);
                console.log(this.effectList, this.effectStandardList)
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                setTimeout(() => this.loading = false, 500)
                setTimeout(() => this.loadingStandard = false, 500)
            })
        },


        getStandardList() {
            this.getStandardServiceList({
                productCode: this.$route.query.id,
                categoryCode: this.productData.categoryCode
            }).then(res => {
                if (!res.result) return;
                let list = [];
                //过滤以选项（ps:应产品要求去掉筛选，只要是有标准功能就不置灰按钮）
                res.result.forEach(item => {
                    // if (!item.selected) {
                        list.push(item);
                    // }
                })
                this.standardList = list;
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        /**
         * 设置列表合并
         * @param row 行数据
         * @param columnIndex 行数据列号
         * @returns {{colspan: (string|*), rowspan: (string|*)}}
         */
        objectSpanMethod({row, columnIndex}) {
            if (columnIndex == 0 || columnIndex == 1 || columnIndex == 2 || columnIndex == 8 || columnIndex == 9 || columnIndex == 11) {
                return {
                    rowspan: row.rowspan,
                    colspan: row.colspan,
                };
            }
        },

        /**
         * 导出自定义功能excel文件
         */
        leadingOut() {
            this.exportFunction({
                productCode: this.$route.query.id
            }).then(res => {
                console.log(res);
                if (res.data instanceof Blob) {
                    const fileName =
                        this.productData.pid + '.xls';
                    this.downFile(res.data, fileName);
                    return;
                }
                this.$dialog.showMessage(res.data.resultMsg, this.$config.TOAST_ERROR);
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        /**
         * 文件下载
         * @param data
         * @param fileName
         * @returns {boolean}
         */
        downFile(data, fileName) {
            if (!data) {
                return false;
            }
            // 下面就是DOM操作 1.添加一个a标签 2.给a标签添加了属性 3.给他添加了点击事件(点击后移除)
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        },

        /**
         * 编辑属性
         * @param row 行数据
         */
        editAttribute(row, type) {
            this.$store.commit("effect/SET_ATTRIBUTE_DIALOG_TYPE", type);
            this.$store.commit("effect/SET_ATTRIBUTE_MSG_ITEM", row);
            this.$store.commit("effect/SET_ATTRIBUTE_DIALOG_VISIBLE", true);
        },

        /**
         * 删除属性
         * @param row 行数据
         */
        delAttribute(row) {
            this.$dialog.showConfirm({
                content: "是否删除该属性？",
                title: "提示",
                btn1: "删除",
                btn2: "取消",
                icon: 2
            }, () => {
                this.deleteProductAttribute({
                    productCode: this.$route.query.id,
                    attributeCode: row.attributeCode
                }).then(res => {
                    this.$dialog.showMessage("删除成功", this.$config.TOAST_SUCCESS);
                    this.getMsg();
                }, err => {
                    console.log(err);
                    this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                })
            })
        },

        /**
         * 添加属性
         * @param row 行数据
         */
        addAttribute(row) {
            this.$store.commit("effect/SET_ATTRIBUTE_DIALOG_TYPE", 0);
            this.$store.commit("effect/SET_ATTRIBUTE_MSG_ITEM", row);
            this.$store.commit("effect/SET_ATTRIBUTE_DIALOG_VISIBLE", true);
        },

        /**
         * 编辑功能
         * @param row 行数据
         */
        editEffect(row, type) {
            this.$store.commit("effect/SET_MODIFY_EFFECT_DIALOG_VISIBLE", true);
            this.$store.commit("effect/SET_EFFECT_DIALOG_TYPE", type);
            this.$store.commit("effect/SET_EFFECT_LIST_ITEM", row);
        },

        /**
         * 删除功能
         * @param row 行数据
         */
        delEffect(row) {
            console.log(row);
            this.$dialog.showConfirm({
                content: "是否删除该功能？",
                title: "提示",
                btn1: "删除",
                btn2: "取消",
                icon: 2
            }, () => {
                this.deleteProductService({
                    productCode: this.$route.query.id,
                    serviceCode: row.code
                }).then(res => {
                    this.$dialog.showMessage("删除成功", this.$config.TOAST_SUCCESS);
                    this.getMsg();
                    this.getStandardList();
                }, err => {
                    console.log(err);
                    this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                })
            })
        },

        /**
         * 新增自定义功能
         */
        showCustom() {
            this.$store.commit("effect/SET_ADD_EFFECT_DIALOG_VISIBLE", true);
        },

        /**
         * 新增标准功能
         */
        showStandard() {
            this.$store.commit("effect/SET_ADD_STANDARD_FUNCTIONS_DIALOG_VISIBLE", true);
        }

    }

}

</script>

<style scoped>
.body {
    width: calc(100% - 60px) !important;
    box-shadow: 0 0 4px 0 #BCBCBC;
    border-radius: 8px;
    padding: 16px 20px;
    margin: 10px;
}

.el-card {
    height: 100%;
}

/deep/ .el-card__header {
    padding: 15px !important;
    text-align: left;
    font-size: 16px;
}

/deep/ .el-breadcrumb__inner, /deep/ .el-breadcrumb__separator {
    font-weight: bold !important;
}

.header_btn {
    width: 100%;
}

.btn {
    float: right;
    margin-bottom: 10px;
}

.title {
    float: left;
    margin-top: 8px;
    font-size: 16px;
    color: #333333;
}

/deep/ .el-table__header-wrapper {
    border-radius: 8px 8px 0 0;
}

.el-table {
    border-radius: 8px;
}

.question_coicon {
    color: #3296FA;
    font-size: 16px;
    cursor: pointer;
    font-family: "iconfont" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.question_alicon {
    color: #3296FA;
    font-size: 16px;
    cursor: pointer;
}

.config_item {
    border: 1px solid #CDCDCD;
    height: 100px;
    border-radius: 5px;
    padding: 10px;
    position: relative;
}

.config_item_title {
    float: left;
    font-weight: bold;
    font-size: 14px;
}

.config_item_details {
    margin-top: 30px;
    font-weight: 400;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.40);
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    word-break: break-all;
}

.el-col-8 {
    margin-top: 20px;
}

/deep/ .el-table__body .el-table__row.hover-bg td{
    background-color: #DEE0E5 !important;
}

</style>
