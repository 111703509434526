<template>
    <div
        class="body">

        <!-- 标题 -->
        <div class="config_title">
            <span class="title1" @click="handleClose">高级配置</span>
            <span class="division">/</span>
            <span class="title2">配网引导</span>
        </div>

        <!-- 步骤 -->
        <div class="step_item" v-for="(item, index) in formList" :key="index">

            <div class="step_title">
                <span class="left_title">步骤{{ $config.noToChinese(index + 1) }}</span>
                <el-button type="text" icon="el-icon-delete" style="color: #FF2825;" @click="delStep(index)"
                           class="right_btn" v-if="formList.length > 1">删除
                </el-button>
                <el-button type="text" icon="el-icon-plus" class="right_btn" v-if="index === (formList.length - 1)"
                           @click="addStep" v-show="formList.length < 10">添加步骤
                </el-button>
            </div>

            <el-row>

                <el-col :span="16">

                    <el-form :model="item" :rules="rules" :ref="'ruleForm' + index" size="small"
                             :label-width="formLabelWidth">

                        <el-form-item label="引导标题：" prop="title">
                            <el-input
                                v-model.trim="item.title"
                                placeholder="请输入引导标题">
                            </el-input>
                        </el-form-item>

                        <el-form-item label="引导说明：" prop="explain">
                            <el-input type="textarea" maxlength="40" show-word-limit :rows="4"
                                      v-model="item.explain"></el-input>
                        </el-form-item>

                        <el-form-item label="引导图片：" required>
                            <div class="image" v-if="item.img">
                                <el-button type="text" icon="el-icon-error" size="medium" @click="item.img = ''"
                                           class="close_img"></el-button>
                                <el-image
                                    :src="item.img"
                                    :preview-src-list="[item.img]"
                                    class="avatar"></el-image>
                            </div>
                            <el-upload
                                v-else
                                class="avatar-uploader"
                                :action="$message.uploadImgUrl"
                                :data="{
                                    bucket: 'guidance'
                                }"
                                :headers="{
                                    accessToken: $token.getToken().accessToken,
                                    refreshToken: $token.getToken().refreshToken
                                }"
                                :show-file-list="false"
                                :on-success="($event) =>{handleAvatarSuccess($event,index)}"
                                :on-error="onErr"
                                accept=".png,.jpeg,.jpg,.gif,.bmp"
                                :before-upload="($event) =>beforeAvatarUpload($event,index)">
                                <i class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                            <span class="tips">格式为png、jpeg、jpg、gif、bmp</span>
                        </el-form-item>
                    </el-form>

                </el-col>

                <el-col :span="8">

                    <div class="phone">

                        <div class="phone_title">
                            {{ item.title ? item.title : "引导标题" }}
                        </div>

                        <div class="phone_explain">
                            {{ item.explain ? item.explain : "引导标题" }}
                        </div>

                        <el-image
                            :src="item.img"
                            :preview-src-list="[item.img]"
                            class="phone_img">
                            <div slot="error">
                                <div class="image_slot">
                                    暂无图片预览
                                </div>
                            </div>
                        </el-image>

                        <el-button type="primary" size="small" class="next_btn">下一步</el-button>

                    </div>
                </el-col>

            </el-row>

        </div>


        <div class="footer">

            <el-button type="primary" :loading="loading" size="small" @click="submit">
                {{ loading ? '提交中 ...' : '确 定' }}
            </el-button>

            <el-button @click="handleClose" size="small">取 消</el-button>

        </div>

    </div>

</template>

<script>

import {mapActions} from "vuex";

export default {

    name: "guidance",

    data() {

        return {

            loading: false,

            formLabelWidth: '100px',

            formList: [],

            id: "",

            rules: {
                title: [
                    {required: true, message: "请输入引导标题"},
                    {pattern: /^(?=[\S\s]{1,20}$)[\S\s]*/, message: '输入不能超过20个字符'},
                ],
                explain: [
                    {required: true, message: "请输入引导说明"},
                    {pattern: /^(?=[\S\s]{1,40}$)[\S\s]*/, message: '输入不能超过40个字符'},
                ]
            }

        }

    },

    mounted() {

        this.formList = [{
            title: "",
            explain: "",
            img: ""
        }]

    },

    methods: {

        ...mapActions('guidance', ['addGuidance', 'queryGuidanceList', 'updateGuidance']),

        getMsg() {
            this.queryGuidanceList({
                productCode: this.$route.query.id,
                type: 1
            }).then(res => {
                if (res.result.length > 0) {
                    this.id = res.result[0].id;
                    this.formList = JSON.parse(res.result[0].content)
                }
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        handleClose() {
            this.$emit('close')
        },

        async submit() {
            let isStop = false;

            for (let i = 0; i < this.formList.length; i++) {

                if (this.formList[i].img === "") {
                    this.$dialog.showMessage("步骤" + this.$config.noToChinese(i + 1) + "，请上传引导图！", this.$config.TOAST_WARNING);
                    isStop = true;
                }

                await this.$refs['ruleForm' + i][0].validate((valid) => {
                    if (!valid) isStop = true;
                })
            }

            if (!isStop) {
                this.loading = true;
                this.$store.dispatch(this.id ? 'guidance/updateGuidance' : 'guidance/addGuidance', {
                    id: this.id ? this.id : null,
                    productCode: this.$route.query.id,
                    type: 1,
                    content: JSON.stringify(this.formList)
                }).then(res => {
                    this.$dialog.showMessage("提交成功！", this.$config.TOAST_SUCCESS);
                    this.handleClose();
                }, err => {
                    console.log(err);
                    this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                }).finally(() => {
                    this.loading = false;
                })
            }


        },

        addStep() {
            this.formList.push({
                title: "",
                explain: "",
                img: ""
            })
        },

        delStep(index) {
            this.formList.splice(index, 1);
        },

        /**
         * 图片上传失败回调
         */
        onErr(err, file, fileList) {
            console.log(err)
            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_WARNING);
        },

        handleAvatarSuccess($event, flag) {
            if ($event.success) {
                this.formList[flag].img = $event.result.fileList[0].url;
            } else {
                this.$dialog.showMessage($event.resultMsg, this.$config.TOAST_ERROR);
                console.log($event)
            }
        },

        beforeAvatarUpload(file, flag) {
            const isJPG = file.type === 'image/jpg';
            const isJPEG = file.type === 'image/jpeg';
            const isPNG = file.type === 'image/png';
            const isGIF = file.type === 'image/gif';
            const isBMP = file.type === 'image/bmp';
            const isLt10M = file.size / 1024 / 1024 < 10;
            console.log(file)
            if (!(isJPG || isJPEG || isPNG || isGIF || isBMP)) {
                this.$dialog.showMessage('图片仅支持jpg、jpeg、png、gif、bmp格式！', this.$config.TOAST_WARNING);
            }
            if (!isLt10M) {
                this.$dialog.showMessage('图片大小不能超过 10MB!', this.$config.TOAST_WARNING);
            }

            const isSize = new Promise(function (resolve, reject) {
                let width = 320;
                let height = 320;
                let _URL = window.URL || window.webkitURL;
                let image = new Image();
                image.onload = function () {
                    let valid = image.width == width && image.height == height;
                    valid ? resolve() : reject();
                };
                image.src = _URL.createObjectURL(file);
            }).then(
                () => {
                    return file;
                },
                () => {
                    this.$dialog.showMessage('上传图片尺寸不符合,只能是320*320!', this.$config.TOAST_WARNING);
                    return Promise.reject();
                }
            );
            return (isJPG || isJPEG || isPNG || isGIF || isBMP) && isLt10M && isSize;
        }

    }

}
</script>

<style scoped>
.body {
    width: calc(100% - 60px) !important;
    box-shadow: 0 0 4px 0 #BCBCBC;
    border-radius: 8px;
    padding: 16px 20px;
    margin: 10px;
}

.title1 {
    font-weight: 400;
    font-size: 14px;
    position: relative;
    top: -2px;
    color: rgba(0, 0, 0, 0.40);
    cursor: pointer;
}

.title2 {
    font-weight: 400;
    font-size: 14px;;
    position: relative;
    top: -2px;
    color: rgba(0, 0, 0, 0.90);
}

.division {
    margin: 0 7px;
    font-size: 18px;
}

.config_title {
    padding-bottom: 10px;
    padding-left: 20px;
    width: calc(100% + 20px);
    margin-left: -20px;
    border-bottom: 1px solid #E9E9E9;
}

.step_item {
    width: 100%;
    margin-top: 20px;
}

.step_title {
    background: #F7F7F7;
    border-radius: 6px;
    padding: 6px 10px;
}

.left_title {
    font-weight: 500;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
}

.right_btn {
    float: right;
    padding: 0;
    margin-top: 3px;
    margin-left: 10px;
}

.el-row {
    margin-top: 30px;
}

.phone {
    width: 180px;
    height: 360px;
    background: url("../../../../assets/phone.png") no-repeat;
    background-size: 100% 100%;
    float: right;
    padding: 40px 20px;
    position: relative;
}

.close_img {
    color: black;
    position: absolute;
    right: 4px;
    margin-top: -14px;
    z-index: 99;
}

.image {
    position: relative;
    width: 100px;
    height: 100px;
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 10px;
}

.tips {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.25);
}

.avatar-uploader .el-upload, .avatar-uploader-icon {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover, .avatar-uploader-icon:hover {
    border-color: #1c204f;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.avatar {
    width: 100px;
    height: 100px;
    display: block;
}

.el-input, .el-textarea {
    width: 80%;
}

.phone_title {
    font-weight: 500;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    word-break: break-all;
}

.phone_explain {
    font-weight: 400;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    word-break: break-all;
}

.phone_img {
    width: 180px;
    height: 180px;
    float: left;
    background-color: #f7f7f7;
    border-radius: 8px;
    margin-top: 30px;
}

.image_slot {
    background: rgba(24, 144, 255, 0.08);
    border-radius: 3px;
    width: 180px;
    height: 180px;
    font-size: 12px;
    text-align: center;
    line-height: 180px;
    color: #c0c4cc;
}

.next_btn {
    width: 180px;
    position: absolute;
    bottom: 60px;
    left: 20px;
}

.footer {
    text-align: center;
    margin-top: 20px;
}
</style>
