<template>
    <div class="body">
        <el-timeline style="margin-top: 20px;">
            <el-timeline-item
                v-for="(item, index) in activities"
                :timestamp="item"
                :key="index"
                color="#1890FF"
                size="large"
                :icon="'iconfont_al step_icon icon-' + (index+1)"
                placement="top">
                <div v-if="index == 0" class="item">
                    <el-form :model="form1" size="small" :rules="rules1" ref="ruleForm" class="basic_form1"
                             :label-width="formLabelWidth" :disabled="form1.state == 3">
                        <el-form-item label="产品名称：" prop="productName">
                            <el-input
                                v-model.trim="form1.productName"
                                placeholder="请输入产品名称">
                            </el-input>
                        </el-form-item>

                        <el-form-item label="产品品类：">
                            {{ form1.categoryName }}
                        </el-form-item>

                        <el-form-item label="产品型号：" prop="model">
                            <el-input
                                v-model.trim="form1.model"
                                placeholder="请输入型号">
                            </el-input>
                        </el-form-item>

                        <el-form-item label="产品拟物图：" required :error="err.fileErr">

                            <!--                            <el-card :body-style="{ padding: '0px' }" class="product_card" v-if="form1.productImageUrl">-->
                            <!--                                <el-button type="text" icon="el-icon-close" class="clean_btn"-->
                            <!--                                           @click="form1.productImageUrl = ''"></el-button>-->
                            <!--                                <img :src="form1.productImageUrl" class="image" width="100" height="100">-->
                            <!--                            </el-card>-->
                            <div class="category_item" v-if="form1.productImageUrl">
                                <div class="image">
                                    <el-button v-if="form1.state != 3" type="text" icon="el-icon-error" size="medium"
                                               @click="form1.productImageUrl = ''" class="close_img"></el-button>
                                    <img :src="form1.productImageUrl" width="94" height="94">
                                </div>
                            </div>
                            <el-upload
                                v-else
                                class="avatar-uploader"
                                :action="$message.uploadImgUrl"
                                :data="{
                                    bucket: 'product'
                                }"
                                :headers="{
                                    accessToken: $token.getToken().accessToken,
                                    refreshToken: $token.getToken().refreshToken
                                }"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                                :on-remove="onRemove"
                                accept=".png"
                                :on-error="onErr"
                                :before-upload="beforeAvatarUpload">
                                <i class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>

                        </el-form-item>
                    </el-form>
                </div>
                <div v-if="index == 1" class="item">
                    <el-form :model="form2" :rules="rules2" :label-width="'10px'" ref="ruleForm1" size="small"
                             class="basic_form2">
                        <el-form-item label=" " prop="firmwareId">
                            <el-select v-model="form2.firmwareId" :popper-append-to-body="false" placeholder="请选择固件" :disabled="form1.state == 3">
                                <el-option
                                    v-for="item in firmwareList"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </div>
                <div v-if="index == 2" class="item">
                    <el-form :model="form3" :rules="rules3" ref="ruleForm2" :label-width="'10px'" size="small"
                             class="basic_form3">
                        <el-form-item label=" " prop="panelId">
                            <el-select v-model="form3.panelId" :popper-append-to-body="false" placeholder="请选择面板" :disabled="form1.state == 3">
                                <el-option
                                    v-for="item in panelList"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </div>
                <div v-if="index == 3" class="item">
                    <el-button :loading="submitLoading" size="small" type="primary" @click="submit()"
                               :disabled="form1.state == 3">确认上线
                    </el-button>
                </div>
            </el-timeline-item>
        </el-timeline>
    </div>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {
    components: {},
    computed: {
        ...mapState('product', ['productMsg'])
    },
    watch: {
        productMsg: {
            handler(newVal) {
                console.log(newVal)
                if (!newVal.isRefresh) return
                this.form1 = {
                    productName: newVal.name,
                    state: newVal.status,
                    categoryCode: newVal.categoryCode,
                    productImageUrl: newVal.icon,
                    model: newVal.model,
                    categoryName: newVal.categoryName ? JSON.parse(newVal.categoryName).zh : "",
                    schemeType: newVal.schemeType
                };
                this.form2.firmwareId = newVal.firmware ? newVal.firmware : "";
                this.form3.panelId = newVal.apply ? newVal.apply : "";
                console.log(this.form1)
            }
        }
    },
    data() {
        return {
            formLabelWidth: '120px',
            submitLoading: false,
            activities: [
                "请确认基础信息",
                "请确认固件版本",
                "请确认RN应用版本",
                "确认上线"
            ],
            firmwareList: [],
            panelList: [],
            err: {
                fileErr: "",
                firmwareErr: "",
                panelErr: ""
            },
            form1: {
                productName: "",
                state: "",
                categoryName: "",
                categoryCode: "",
                model: "",
                productImageUrl: "",
                schemeType: ""
            },
            form2: {
                firmwareId: ''
            },
            form3: {
                panelId: ''
            },
            isRemoveImg: false,
            rules1: {
                productName: [
                    {required: true, message: "请输入产品名称"},
                    {pattern: /^(?=[\S\s]{1,20}$)[\S\s]*/, message: '输入不能超过20个字符'},
                ],
                model: [
                    {required: true, message: "请输入产品型号"},
                    {pattern: /^(?=[\S\s]{1,20}$)[\S\s]*/, message: '输入不能超过20个字符'},
                    {pattern: /^[a-z_A-Z_0-9]*$/, message: '只能输入大小写字母、下划线及数字'},
                ]
            },
            rules2: {
                firmwareId: [
                    {required: true, message: "请选择固件"}
                ]
            },
            rules3: {
                panelId: [
                    {required: true, message: "请选择面板"}
                ]
            }
        }
    },
    mounted() {
    },
    methods: {

        ...mapActions('product', ['publishProduct']),
        ...mapActions('firmware', ['queryFirmwareList']),
        ...mapActions('panel', ['queryApplyList']),

        init() {
            this.form1 = {
                productName: this.productMsg.name,
                productImageUrl: this.productMsg.icon,
                state: this.productMsg.status,
                categoryCode: this.productMsg.categoryCode,
                model: this.productMsg.model,
                categoryName: JSON.parse(this.productMsg.categoryName).zh,
                schemeType: this.productMsg.schemeType
            };
            this.form2.firmwareId = this.productMsg.firmware ? this.productMsg.firmware : "";
            this.form3.panelId = this.productMsg.apply ? this.productMsg.apply : "";

            //获取已发布固件版本列表
            this.queryFirmwareList({
                productCode: this.$route.query.id,
                status: 2
            }).then(res => {
                let list = [];
                if (res.result.length > 0) {
                    res.result.forEach(item => {
                        //过滤调嵌入式开发已发布的SDK固件
                        console.log(this.form1.schemeType , item.type, this.form1.schemeType === item.type)
                        this.form1.schemeType === item.type && list.push(item)
                    })
                }
                this.firmwareList = list;
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            });

            //获取已发布应用版本列表
            this.queryApplyList({
                productCode: this.$route.query.id,
                status: 2
            }).then(res => {
                this.panelList = res.result;
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        handleAvatarSuccess(res, file) {
            if (res.success) {
                this.form1.productImageUrl = res.result.fileList[0].url;
                this.err.fileErr = ""
            } else {
                this.$dialog.showMessage(res.resultMsg, this.$config.TOAST_ERROR);
                console.log(res, file)
            }
        },

        beforeAvatarUpload(file) {
            const isPNG = file.type === 'image/png';
            const isLt2M = file.size / 1024 < 100;
            const isSize = new Promise(function (resolve, reject) {
                let width = 168;
                let height = 168;
                let _URL = window.URL || window.webkitURL;
                let image = new Image();
                image.onload = function () {
                    let valid = image.width == width && image.height == height;
                    valid ? resolve() : reject();
                };
                image.src = _URL.createObjectURL(file);
                console.log(image.src)
            }).then(
                () => {
                    return file;
                },
                () => {
                    this.$dialog.showMessage('上传图片尺寸不符合,只能是168*168!', this.$config.TOAST_WARNING);
                    return Promise.reject();
                }
            );
            console.log(file)
            if (!isPNG) {
                this.$dialog.showMessage('产品拟物图仅支持PNG格式！', this.$config.TOAST_WARNING);
            }
            if (!isLt2M) {
                this.$dialog.showMessage('上传图片大小不能超过 100KB！', this.$config.TOAST_WARNING);
            }
            return isPNG && isLt2M && isSize;
        },

        onErr(err, file, fileList) {
            console.log(err)
            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_WARNING);
        },

        onRemove() {
            this.isRemoveImg = true;
        },

        submit() {
            let isStop = false;
            if (this.form1.productImageUrl == "") {
                this.err.fileErr = "请上传产品拟物图";
                isStop = true;
            }
            this.$refs.ruleForm1[0].validate((valid) => {
                if (!valid) {
                    isStop = true;
                }
            })
            this.$refs.ruleForm2[0].validate((valid) => {
                if (!valid) {
                    isStop = true;
                }
            })
            this.$refs.ruleForm[0].validate((valid) => {
                if (!isStop && valid) {
                    this.err = {
                        fileErr: "",
                        firmwareErr: "",
                        panelErr: ""
                    };
                    this.publish()
                }
            })
        },
        publish() {
            this.submitLoading = true;
            this.publishProduct({
                apply: this.form3.panelId,
                categoryCode: this.form1.categoryCode,
                firmware: this.form2.firmwareId,
                icon: this.form1.productImageUrl,
                model: this.form1.model,
                name: this.form1.productName,
                productCode: this.$route.query.id
            }).then(res => {
                this.$dialog.showMessage("发布成功", this.$config.TOAST_SUCCESS);
                this.$router.push({path: "/index"});
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.submitLoading = false;
            })
        }
    }
}
</script>

<style scoped>
.body {
    width: calc(100% - 60px) !important;
    height: calc(100% - 35px) !important;
    box-shadow: 0 0 4px 0 #BCBCBC;
    border-radius: 8px;
    padding: 16px 20px;
    margin: 10px;
}

.category_item {
    margin-bottom: 10px;
    text-align: center;
    padding-top: 10px;
}

.image {
    position: relative;
    width: 94px;
    height: 94px;
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 10px;
}

.close_img {
    color: black;
    position: absolute;
    right: 8px;
    margin-top: -12px;
}

.item {
    margin: 20px 0 40px 0;
}

.basic_form1 {
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    border-radius: 8px;
    padding: 24px 20px 0 0;
}

/deep/ .step_icon {
    margin-top: 3.5px
}

/deep/ .el-divider--horizontal {
    margin: 15px 0;
}

/deep/ .el-timeline-item__node--large {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
}

/deep/ .el-timeline-item__tail {
    left: 7px;
}

/deep/ .el-timeline-item__timestamp {
    font-size: 16px;
    color: #000;
}


.avatar-uploader .el-upload, .avatar-uploader-icon {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover, .avatar-uploader-icon:hover {
    border-color: #1c204f;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.product_card {
    width: 100px;
    text-align: center;
}

.clean_btn {
    margin: 5px 5px -10px 0;
    padding: 0 !important;
    float: right;
    position: relative;
    z-index: 999;
}

.el-input {
    width: 680px;
}
</style>
