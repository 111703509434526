<template>

    <div class="body">

        <el-timeline style="margin-top: 20px;">

            <el-timeline-item
                v-for="(item, index) in activities"
                :timestamp="item"
                :key="index"
                color="#1890FF"
                size="large"
                :icon="'iconfont_al step_icon icon-' + (index+1)"
                placement="top">

                <div v-if="index == 0" class="item">
                    <el-button
                        v-if="!selectModuleMsg.code"
                        type="primary"
                        plain
                        class="debugging"
                        @click="selectModule">
                        选择模组
                    </el-button>
                    <el-card
                        :body-style="{padding: '10px',marginBottom: '0'}"
                        shadow="never"
                        class="module_item"
                        v-else>

                        <el-row>
                            <el-col :span="4">
                                <el-image :src="selectModuleMsg.icon" class="product_img">
                                    <div slot="error">
                                        <div class="image_slot">
                                            没有模组拟物图
                                        </div>
                                    </div>
                                </el-image>
                            </el-col>
                            <el-col :span="16">
                                <el-descriptions title="" :column="2" style="margin-top: 13px;margin-left: 20px;">
                                    <el-descriptions-item label="型号">{{ selectModuleMsg.model }}</el-descriptions-item>
                                    <el-descriptions-item label="芯片">{{ selectModuleMsg.chip }}</el-descriptions-item>
                                    <el-descriptions-item label="天线">
                                        {{ $config.getMsgItemUtil($message.antennaTypeList, selectModuleMsg.antenna, 'id', 'name') }}
                                    </el-descriptions-item>
                                </el-descriptions>
                            </el-col>
                            <el-col :span="4">
                                <el-button type="text" class="reselection" @click="selectModule">重新选择</el-button>
                            </el-col>
                        </el-row>
                    </el-card>
                </div>

                <div v-if="index == 1" class="item">
                    <el-row>
                        <el-col :span="5">
                            <el-card
                                :body-style="{padding: '10px',marginBottom: '0'}"
                                shadow="never">
                                MCU SDK

                                <el-link type="primary" style="float: right;" :href="url.appSdkUrl">下载</el-link>
                            </el-card>
                        </el-col>
                        <el-col :span="5" style="margin-left: 30px;">
                            <el-card
                                :body-style="{padding: '10px',marginBottom: '0'}"
                                shadow="never">
                                串口通讯协议
                                <el-link type="primary" style="float: right;" :href="url.deviceSdkUrl">下载</el-link>
                            </el-card>
                        </el-col>
                    </el-row>
                </div>

                <div v-if="index == 2" class="item">
                    <el-button
                        type="primary"
                        plain
                        class="debugging"
                        @click="toDebug">
                        前往调试
                        <i class="el-icon-arrow-right el-icon--right"></i>
                    </el-button>
                </div>

                <div v-if="index == 3" class="item">
                    <div class="step4">
                        <div class="header_btn">
                            <div class="title1">MCU固件版本列表</div>
                            <el-button class="btn" size="small" type="primary" @click="add(2)" plain>新建版本</el-button>
                        </div>

                        <el-table
                            ref="firmwareTable"
                            class="firmware_table"
                            :data="mcuFirmwareMsg.list"
                            :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)',fontSize:'14px', paddingLeft: '24px'}"
                            :cell-style="{color:'rgba(0, 0, 0 ,.65)', padding: '16px 24px'}"
                            v-loading="loading1"
                            style="width: 100%;">
                            <el-table-column
                                label="固件版本"
                                prop="name">
                            </el-table-column>
                            <el-table-column
                                prop="remark"
                                label="更新日志">
                                <template slot-scope="scope">
                                    <span
                                        :title="scope.row.remark"
                                        style="
                                        display: -webkit-box;;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                        -webkit-line-clamp: 2;
                                        -webkit-box-orient: vertical;
                                        white-space: pre-line;
                                        ">
                                        {{ scope.row.remark }}
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="createTime"
                                label="上传时间">
                            </el-table-column>
                            <el-table-column
                                label="状态">
                                <template slot-scope="scope">
                                    <span class="status unpublished" v-if="scope.row.status === 1">未发布</span>
                                    <span class="status published" v-else>已发布</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="操作">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.status === 1">
                                        <el-button type="text" size="small"
                                                   @click="release(scope.row, 0)">发布
                                        </el-button>
                                        <el-button type="text" size="small" style="color:#d45c34;"
                                                   @click="del(scope.row, 0)">删除
                                        </el-button>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>

                        <el-pagination
                            background
                            :current-page="page"
                            @current-change="handleCurrentChange"
                            :page-size="10"
                            layout=" prev, pager, next"
                            style="margin-top: 10px;text-align: right;"
                            :total="parseInt(mcuFirmwareMsg.totalRecords)">
                        </el-pagination>
                    </div>

<!--                    <div class="step4" style="margin-top: 10px;">-->
<!--                        <div class="header_btn">-->
<!--                            <div class="title1">含SDK固件版本</div>-->
<!--                            <el-button class="btn" size="small" type="primary" @click="add(1)" plain>新建版本</el-button>-->
<!--                        </div>-->

<!--                        <el-table-->
<!--                            ref="firmwareTable"-->
<!--                            class="firmware_table"-->
<!--                            :data="firmwareMsg.list"-->
<!--                            :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)',fontSize:'14px', paddingLeft: '24px'}"-->
<!--                            :cell-style="{color:'rgba(0, 0, 0 ,.65)', padding: '16px 24px'}"-->
<!--                            v-loading="loading"-->
<!--                            style="width: 100%;">-->
<!--                            <el-table-column-->
<!--                                label="固件版本"-->
<!--                                prop="name">-->
<!--                            </el-table-column>-->
<!--                            <el-table-column-->
<!--                                prop="remark"-->
<!--                                label="更新日志">-->
<!--                                <template slot-scope="scope">-->
<!--                                    <span-->
<!--                                        :title="scope.row.remark"-->
<!--                                        style="-->
<!--                                        display: -webkit-box;;-->
<!--                                        text-overflow: ellipsis;-->
<!--                                        overflow: hidden;-->
<!--                                        -webkit-line-clamp: 2;-->
<!--                                        -webkit-box-orient: vertical;-->
<!--                                        white-space: pre-line;-->
<!--                                        ">-->
<!--                                        {{ scope.row.remark }}-->
<!--                                    </span>-->
<!--                                </template>-->
<!--                            </el-table-column>-->
<!--                            <el-table-column-->
<!--                                prop="createTime"-->
<!--                                label="上传时间">-->
<!--                            </el-table-column>-->
<!--                            <el-table-column-->
<!--                                label="状态">-->
<!--                                <template slot-scope="scope">-->
<!--                                    <span class="status unpublished" v-if="scope.row.status === 1">未发布</span>-->
<!--                                    <span class="status published" v-else>已发布</span>-->
<!--                                </template>-->
<!--                            </el-table-column>-->
<!--                            <el-table-column-->
<!--                                label="操作">-->
<!--                                <template slot-scope="scope">-->
<!--                                    <div v-if="scope.row.status === 1">-->
<!--                                        <el-button type="text" size="small"-->
<!--                                                   @click="release(scope.row, 1)">发布-->
<!--                                        </el-button>-->
<!--                                        <el-button type="text" size="small" style="color:#d45c34;"-->
<!--                                                   @click="del(scope.row, 1)">删除-->
<!--                                        </el-button>-->
<!--                                    </div>-->
<!--                                </template>-->
<!--                            </el-table-column>-->
<!--                        </el-table>-->

<!--                        <el-pagination-->
<!--                            background-->
<!--                            @current-change="handleCurrentChange1"-->
<!--                            :page-size="10"-->
<!--                            layout=" prev, pager, next"-->
<!--                            style="margin-top: 10px;text-align: right;"-->
<!--                            :total="parseInt(firmwareMsg.totalRecords)">-->
<!--                        </el-pagination>-->
<!--                    </div>-->
                </div>

            </el-timeline-item>

        </el-timeline>

        <!-- 上传固件包弹窗 -->
        <add-firmware-dialog @getMsg="getMcuFirmwareMsg"></add-firmware-dialog>

        <!-- 模组列表弹窗 -->
        <module-dialog></module-dialog>

    </div>

</template>

<script>

import AddFirmwareDialog from "./components/dialog/addFirmwareDialog";
import {mapActions, mapState} from 'vuex'
import ModuleDialog from "@/views/product/views/firmware/components/dialog/moduleDialog";

export default {

    components: {
        ModuleDialog,
        AddFirmwareDialog
    },

    computed: {
        ...mapState('product', ['productMsg']),
        ...mapState('firmware', ['moduleList', 'selectModuleId'])
    },

    watch: {

        productMsg: {
            handler(newVal) {
                this.productData = newVal;
            }
        },

        selectModuleId: {
            handler(newVal) {
                this.selectModuleMsg.code = newVal;
                console.log('selectModuleId => ', newVal);
                if (this.moduleList.length > 0) {
                    this.getSelectModuleMsg()
                }
            }
        },

        moduleList: {
            handler(newVal) {
                if (this.selectModuleMsg.code) {
                    this.getSelectModuleMsg()
                }
            }
        }

    },

    data() {

        return {

            accessMode: 0,

            isLookUp: false,

            loading: false,

            loading1: false,

            firmwareMsg: {},

            mcuFirmwareMsg: {},

            productData: {},

            activities: [
                "请选择接入云端模组",
                "请下载开发资料",
                "设备调试（调试前请确认是否已开发完成）",
                "上传固件"
            ],

            page: 1,

            page1: 1,

            voucher: {
                pid: "",
                productSecret: ""
            },

            url: {
                appSdkUrl: "",
                deviceSdkUrl: ""
            },

            selectModuleMsg: {}

        }

    },

    mounted() {
        this.productData = this.productMsg;
        this.selectModuleMsg.code = this.productMsg.moduleCode;
        this.$store.commit('firmware/SET_SELECT_MODULE_ID', this.productMsg.moduleCode)
        console.log('firmware/SET_SELECT_MODULE_ID', this.productMsg.moduleCode)
        // this.getProductBurningInfo({
        //     productCode: this.$route.query.id
        // }).then(res => {
        //     this.voucher = res.result;
        // }, err => {
        //     console.log(err);
        //     this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
        // });
        // this.getFirmwareMsg();
        // this.getMcuFirmwareMsg();
        this.getFirmwareResource({}).then(res => {
            this.url = res.result;
        }, err => {
            console.log(err);
        });
        this.getModuleListByCategoryCode({
            categoryCode: this.productData.categoryCode
        });
    },

    methods: {

        ...mapActions('firmware', ['queryFirmware', 'getProductBurningInfo', 'delFirmware', 'pubFirmware', 'getFirmwareResource', "getModuleListByCategoryCode"]),
        ...mapActions('product', ['getProductDetail']),

        getSelectModuleMsg() {
            this.moduleList.forEach(item => {
                if (item.code === this.selectModuleMsg.code) {
                    this.selectModuleMsg = this.$config.deepCopy(item);
                }
            })
            console.log('selectModuleId1 => ', this.selectModuleMsg.module);
        },

        add(type) {
            this.$store.commit('firmware/SET_FIRMWARE_DIALOG_TYPE', type);
            this.$store.commit('firmware/SET_ADD_FIRMWARE_DIALOG_VISIBLE', true);
        },

        toDebug() {
            this.$router.push({path: "/debug", query: {id: this.$route.query.id}});
        },

        selectModule() {
            this.$store.commit('firmware/SET_MODULE_DIALOG_VISIBLE', true);
        },

        getFirmwareMsg() {
            this.loading = true;
            this.queryFirmware({
                productCode: this.$route.query.id,
                currPage: this.page,
                pageSize: 10,
                type: 1
            }).then(res => {
                this.firmwareMsg = res.result;
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.loading = false;
            })
        },

        getMcuFirmwareMsg() {
            this.loading1 = true;
            this.queryFirmware({
                productCode: this.$route.query.id,
                currPage: this.page1,
                pageSize: 10,
                type: 2
            }).then(res => {
                this.mcuFirmwareMsg = res.result;
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                setTimeout(() => this.loading1 = false, 500)
            })
        },

        handleCurrentChange(page) {
            this.page1 = page;
            this.getMcuFirmwareMsg()
        },

        handleCurrentChange1(page) {
            this.page = page;
            this.getFirmwareMsg()
        },

        release(row, type) {
            this.$dialog.showConfirm({
                content: "是否发布当前固件？",
                title: "提示",
                btn1: "确认",
                btn2: "取消",
                icon: 2
            }, () => {
                this.pubFirmware({
                    code: row.code
                }).then(res => {
                    this.$dialog.showMessage("发布成功", this.$config.TOAST_SUCCESS);
                    type === 0 ? this.getMcuFirmwareMsg() : this.getFirmwareMsg();
                    this.getProductDetail({
                        productCode: this.$route.query.id,
                        isRefresh: true
                    });
                }, err => {
                    console.log(err);
                    this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                })
            }, () => {
            })
        },

        del(row, type) {
            this.$dialog.showConfirm({
                content: "是否删除当前固件？",
                title: "提示",
                btn1: "确认",
                btn2: "取消",
                icon: 2
            }, () => {
                this.delFirmware({
                    code: row.code
                }).then(res => {
                    this.$dialog.showMessage("删除成功", this.$config.TOAST_SUCCESS);
                    type === 0 ? this.getMcuFirmwareMsg() : this.getFirmwareMsg();
                }, err => {
                    console.log(err);
                    this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                })
            }, () => {
            })
        }

    }

}
</script>

<style scoped>
.body {
    width: calc(100% - 60px) !important;
    height: calc(100% - 35px) !important;
    box-shadow: 0 0 4px 0 #BCBCBC;
    border-radius: 8px;
    padding: 16px 20px;
    margin: 10px;
}

.el-card {
    border-radius: 8px;
    border: 1px solid #E1E1E1;
}

.title {
    font-weight: bold;
}

/deep/ .el-table__header-wrapper {
    border-radius: 8px 8px 0 0;
}

.access_mode {
    margin-top: 20px;
}

.item {
    margin: 20px 0 40px 0;
}

/deep/ .el-divider--horizontal {
    margin: 15px 0;
}

/deep/ .el-timeline-item__node--large {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
}

/deep/ .el-timeline-item__tail {
    left: 7px;
}

/deep/ .el-timeline-item__timestamp {
    font-size: 16px;
    color: #000;
}

/deep/ .step_icon {
    margin-top: 3.5px
}

.step2 {
    width: 400px;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    border-radius: 8px;
    padding: 10px;
}

.look_up {
    float: right;
    margin-top: -40px;
}

.step4 {
    width: calc(100% - 100px);
    padding: 10px;
    margin-top: -10px;
}

.header_btn {
    width: 100%;
    height: 30px;
}

.debugging {
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    border-radius: 8px;
}

.btn {
    float: right;
    margin-bottom: 10px;
}

.title1 {
    float: left;
    margin-top: 8px;
    font-size: 16px;
    color: black;
    font-weight: bold;
}

/deep/ .cell {
    padding: 0 !important;
}

.module_item {
    width: 66%;
}

.product_img {
    width: 60px;
    height: 60px;
    background-color: #f7f7f7;
    border-radius: 8px;
    margin: 10px 0;
}

.reselection {
    margin-top: 23px;
    float: right;
    margin-right: 20px;
}

.image_slot {
    background-color: #F7F7F7;
    width: 50px;
    height: 50px;
    font-size: 12px;
    text-align: center;
    padding: 13px 5px 0 5px;
    color: #c0c4cc;
}

.status {
    width: auto;
    font-size: 12px;
    padding: 2px;
    border-radius: 4px;
}

.unpublished {
    border: 1px solid #888888;
    color: #888888;
}

.published {
    border: 1px solid #389E0D;
    color: #389E0D;
}
</style>
