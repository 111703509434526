<template>
    <div>
        <!-- 标题 start -->
        <div class="page_title">
            <i class="el-icon-arrow-left" style="cursor: pointer;" @click="back()"></i>
            产品编辑
        </div>
        <!-- 标题 end -->

        <!-- 卡片 start -->
        <el-card
            :body-style="{
                textAlign: 'left'
            }"
            shadow="never">

            <!-- 头部产品详情 start -->
            <div class="product_msg">
                <el-image :src="form.productImageUrl" :preview-src-list="[form.productImageUrl]" class="product_img">
                    <div slot="error">
                        <div class="image_slot">
                            没有产品拟物图
                        </div>
                    </div>
                </el-image>
                <div class="product_msg_list">
                    <div class="product_msg_top">
                        <div class="product_name">{{ form.productName }}</div>
                        <div :class="form.state === 3 ? 'product_state_published' : 'product_state_developing'">
                            {{ $config.getMsgItemUtil($message.productStatusList, form.state, 'id', 'name') }}
                        </div>
                        <el-button v-if="form.state !== 3" type="text" icon="el-icon-edit" class="product_edit"
                                   @click="editMsg()"></el-button>
                    </div>
                    <div class="product_msg_bottom">
                        <div class="product_msg_item">
                            PID：<span id="pid">{{ form.pid }}</span>
                            <i class="el-icon-copy-document copy" data-clipboard-target="#pid"></i>
                        </div>
                        <div class="product_msg_item">产品型号：{{ form.model }}</div>
                        <div class="product_msg_item">品类：{{ selectMsg.name }}</div>
                        <div class="product_msg_item">
                            通讯方式：{{
                                $config.getMsgItemUtil($message.protocolTypeList, form.communication, 'id', 'name')
                            }}
                        </div>
                        <div class="product_msg_item" :title="'备注：' + form.remarks">备注：{{ form.remarks }}</div>
                    </div>
                    <el-button type="text" class="product_msg_btn" @click="showProduct()">查看详情</el-button>
                </div>
            </div>
            <!-- 头部产品详情 end -->

            <!-- tab内容区域 start -->
            <el-tabs v-model="activeName" class="main" @tab-click="handleClick">
                <!--                    <el-tab-pane label="基础配置" name="first">-->
                <!--                        <basic class="content"></basic>-->
                <!--                    </el-tab-pane>-->
                <el-tab-pane :style="{height: height + 'px'}" label="功能定义" name="second" :lazy="true">
                    <effect class="content" ref="effect"></effect>
                </el-tab-pane>
                <el-tab-pane :style="{height: height + 'px'}" label="设备接入" name="third" :lazy="true">
                    <non-embedded class="content" ref="nonEmbedded" v-if="form.schemeType === 1"></non-embedded>
                    <firmware class="content" ref="firmware" v-if="form.schemeType === 2"></firmware>
                </el-tab-pane>
                <el-tab-pane :style="{height: height + 'px'}" label="应用开发" name="fourth" :lazy="true">
                    <panel class="content" ref="panel"></panel>
                </el-tab-pane>
                <el-tab-pane :style="{height: height + 'px'}" label="高级配置" name="fifth" :lazy="true">
                    <config class="content"></config>
                </el-tab-pane>
                <el-tab-pane :style="{height: height + 'px'}" label="产品发布" name="sixth" :lazy="true">
                    <release class="content" ref="release"></release>
                </el-tab-pane>
            </el-tabs>
            <!-- tab内容区域 end -->
        </el-card>
        <!-- 卡片 end -->

        <!-- 编辑产品信息抽屉 -->
        <edit-product-drawer></edit-product-drawer>

        <!-- 产品详情弹窗 -->
        <product-msg-dialog></product-msg-dialog>

    </div>
</template>

<script>

import Effect from "../views/effect/effect";
import Firmware from "../views/firmware/firmware";
import Panel from "../views/panel/panel";
import Release from "../views/release/release";
import editProductDrawer from "@/views/product/views/basic/components/editProductDrawer";
import productMsgDialog from "@/views/product/views/basic/components/productMsgDialog";
import {mapState, mapActions} from "vuex";
import Config from "@/views/product/views/config/config";
import NonEmbedded from "@/views/product/views/firmware/nonEmbedded";
import Clipboard from "clipboard";


export default {

    components: {
        NonEmbedded,
        Config,
        productMsgDialog,
        Release,
        Firmware,
        Effect,
        Panel,
        editProductDrawer
    },

    computed: {
        ...mapState('product', ['selectCategoryMsg', 'productMsg'])
    },

    watch: {
        //产品详情
        productMsg: {
            handler(newVal) {
                this.form = {
                    pid: newVal.pid,
                    state: newVal.status,
                    productName: newVal.name,
                    communication: newVal.protocolType,
                    remarks: newVal.remark,
                    productImageUrl: newVal.icon,
                    model: newVal.model,
                    schemeType: newVal.schemeType
                }
                this.selectMsg = {
                    name: newVal.categoryName ? JSON.parse(newVal.categoryName).zh : "",
                    iconUrl: newVal.categoryIconUrl
                }
            },
            deep: true
        }
    },

    data() {

        return {

            //当前tab页面
            activeName: 'second',

            //上一次tab页面
            lastTimeActiveName: 'second',

            //品类信息
            selectMsg: {},

            //tab页面高度
            height: 50,

            form: {
                pid: "",

                //产品状态
                state: 1,

                //产品名称
                productName: "",

                //品类
                productType: "",

                //通信方式
                communication: 3,

                //备注
                remarks: "",

                //产品拟物图
                productImageUrl: "",

                //产品型号
                model: "",

                //开发方案（1，非嵌入式 2，嵌入式）
                schemeType: ""
            },

            clipboard: null

        }

    },

    mounted() {
        //动态加载表格高度
        setTimeout(this.setHeight, 100)
        window.addEventListener('resize', this.setHeight);
        this.getMsg();

        this.clipboard = new Clipboard('.copy');
        this.clipboard.on('success', (e) => {
            console.log("复制成功", e);
            this.$dialog.showMessage("复制成功", this.$config.TOAST_SUCCESS);
        });
    },

    methods: {

        ...mapActions('product', ['getProductDetail']),

        /**
         * 高度适配
         */
        setHeight() {
            this.height = window.innerHeight - 365;
        },

        /**
         * 打开产品详情弹窗
         */
        showProduct() {
            this.$store.commit("product/SET_PRODUCT_MSG_DIALOG_VISIBLE", true);
        },

        /**
         * 打开编辑产品基本信息侧边栏
         */
        editMsg() {
            this.$store.commit("product/SET_EDIT_PRODUCT_DRAWER_VISIBLE", true);
        },

        /**
         * 获取产品详情
         */
        getMsg() {
            this.getProductDetail({
                productCode: this.$route.query.id,
                isRefresh: true
            });
        },

        /**
         * 返回上一页
         */
        back() {
            this.$router.push({
                path: "/index"
            })
        },

        /**
         * tab标签切换监听
         * @param msg
         * @param evt
         */
        handleClick(msg, evt) {
            if (msg.name === this.lastTimeActiveName) return;
            this.lastTimeActiveName = msg.name;
            switch (msg.name) {
                case 'sixth':
                    this.$nextTick(() => this.$refs.release.init());
                    break;
                case 'second':
                    this.$refs.effect.getMsg();
                    this.$refs.effect.getStandardList();
                    break;
                case 'third':
                    this.$nextTick(() => this.form.schemeType === 1 ?
                        this.$refs.nonEmbedded.getMsg() :
                        this.$refs.firmware.getMcuFirmwareMsg());

                    break;
                case 'fourth':
                    this.$nextTick(() => this.$refs.panel.getMsg());
                    break;
            }
        }

    },

    destroyed() {
        window.removeEventListener('resize', this.setHeight);
        this.clipboard.destroy();
    }

}
</script>

<style scoped>
.el-breadcrumb {
    line-height: normal;
}

/deep/ .el-breadcrumb__inner, /deep/ .el-breadcrumb__separator {
    font-weight: bold !important;
    font-size: 16px !important;
    color: black !important;
}

/deep/ .el-card__body {
    overflow-y: auto;
    margin-bottom: 10px;
}

/deep/ .el-card__header {
    padding: 10px !important;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
}

.el-card {
    background: #FFFFFF;
    border-radius: 8px;
    border: none;
    margin: 64px 24px 24px 24px;
    overflow-y: auto;
}

/deep/ .el-tabs--top {
    height: 100%;
}

.el-row, .box-card, .el-col, .user_right, .routerLink {
    height: 100%;
}

.content {
    width: 100%;
    /*height: calc(100%);*/
    overflow-y: auto;
}

.main-icon {
    font-size: 14px;
    color: #000000;
    font-family: "iconfont" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.product_msg {
    width: 100%;
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0 0 4px 0 #BCBCBC;
    border-radius: 8px;
    position: relative;
}

.product_img {
    width: 60px;
    height: 60px;
    float: left;
    background-color: #f7f7f7;
    border-radius: 8px;
    margin: 10px 20px;
}

.image_slot {
    background-color: #F7F7F7;
    width: 50px;
    height: 50px;
    font-size: 12px;
    text-align: center;
    padding: 13px 5px 0 5px;
    color: #c0c4cc;
}

.product_msg_list {
    height: 60px;
    margin: 10px 0;
    float: left;
}

.product_msg_top {
    height: 22px;
    padding: 7px 0;
}

.product_msg_bottom {
    height: 22px;
}

.product_name {
    font-weight: bold;
    font-size: 16px;
    color: black;
    float: left;
    line-height: 22px;
}

.product_state_developing {
    border: 1px solid #3296FA;
    border-radius: 12px;
    font-weight: 400;
    font-size: 10px;
    color: #3296FA;
    padding: 0 5px;
    float: left;
    margin-left: 21px;
    margin-top: 1px;
}

.product_state_published {
    border: 1px solid rgb(56, 158, 13);
    border-radius: 12px;
    font-weight: 400;
    font-size: 10px;
    color: rgb(56, 158, 13);
    padding: 0 5px;
    float: left;
    margin-left: 21px;
    margin-top: 1px;
}

.product_edit {
    float: left;
    margin-left: 21px;
    height: 22px;
    padding: 0;
}

.product_msg_item {
    float: left;
    font-size: 10px;
    color: #595959;
    font-weight: 400;
    margin-left: 40px;
}

.product_msg_item:first-child {
    margin-left: 0;
}

.product_msg_item:last-child {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.main {
    width: 100%;
    /*height: calc(100% - 150px);*/
    border: none;
    margin-top: 15px;
}

/deep/ .main .el-tabs__header {
    background: #F7F7F7;
    border-radius: 8px;
}

/deep/ .main .el-tabs__nav-wrap::after {
    display: none;
}

/deep/ .main .el-tabs__item {
    padding: 0 20px !important;
    height: 20px;
    line-height: 20px;
    margin: 10px 0;
}

/deep/ .main .el-tabs__item {
    border-right: 1px solid #E1E1E1;
}

/deep/ .main .el-tabs__item:last-child {
    border-right: none;
}

/deep/ .main .el-tabs__content {
    /*width: calc(100% - 48px);*/
    width: 100%;
    background: #FFFFFF;
    overflow-y: auto;
    /*box-shadow: 0 0 4px 0 #BCBCBC;*/
    /*border-radius: 8px;*/
    /*padding: 16px 24px;*/
}

.product_msg_btn {
    position: absolute;
    right: 24px;
    top: 20px;
}

.el-tab{width:99.9%!important}

</style>
